import PropTypes from 'prop-types';
import React from 'react';

import { useSelector } from 'react-redux';

const AlertsMenu = (props, context) => {

  const projectsState = useSelector((state) => state.leaksList.projectsList);
  const selectedProjectId = useSelector((state) => state.leaksList.selectedProject);
  const selectedProjectIndex = projectsState.projectIndexMap[selectedProjectId];
  const projectItem = projectsState.items[selectedProjectIndex];

  const { onChange, user } = props;
  return (
    <div className="manage-menu">
      <button
        className="btn-menu alerts"
        title={context.t('alerts')}
        onClick={() => onChange('alerts')}
      />

      <button
        className="btn-menu noise"
        title={context.t('noise')}
        onClick={() => onChange('noise')}
      >{context.t('n')}</button>


      {JSON.parse(process.env.REACT_APP_SHOW_PRESSURE_ALERTS) && projectItem?.is_pressure &&
        <button
          className="btn-menu pressure"
          title={context.t('pressure')}
          onClick={() => onChange('prsAlerts')}
        >{context.t('p')}</button>}


      {JSON.parse(process.env.REACT_APP_SHOW_VALVE_ALERTS) && user.valveAlerts && projectItem?.is_valve_monitoring &&
        <button
          className="btn-menu valve"
          title={context.t('valve')}
          onClick={() => onChange('valve')}
        >{context.t('v')}</button>
      }

    </div>
  );
}

AlertsMenu.contextTypes = {
  t: PropTypes.func.isRequired
}

export default AlertsMenu;
