/* eslint-disable camelcase */
const KAISER = true; // (ENV !== ENVIRONMENTS.PROD && ENV !== ENVIRONMENTS.TRIMBLE);
const KAISER_FACTOR_1 = 0.5;
const KAISER_FACTOR_2 = 0.3;

function roundFunc(value) {
  return Math.round(value);
}

export function rotateMatrixClockwise(matrix) {
  return new Promise(resolve => {
    resolve(rotateMatrixClockwiseSync(matrix));
  });
}

export function rotateMatrixClockwiseSync(matrix) {
  let result = [];
  if (matrix && matrix[0]) {
    result = matrix[0].map((_column, index) => (
      matrix.map((row) => row[index])
    ));
  }
  return (result);
}

export function setParameters(data, factor) {
  return new Promise((resolve, reject) => {
    if (data == null) {
      reject('no data');
    } else {
      const params = {
        freq: {
          start: data.freq_start,
          end: data.freq_end,
          step: data.freq_step
        },
        pipeLength: data.pipe_len_m,
        factor: factor
      };

      resolve(params);
    }
  });
}

export async function generateGraph(data, pks, locs, factor) {
  const {
    band_num,
    max_delay,
    pipe_len_m,
    pipe_section_in_samples_from_center,
    ave_locks_in_samples,
    ave_locks_in_m
  } = data;

  const sensor1position = (max_delay + 1) + pipe_section_in_samples_from_center[0];
  const sensor2position = (max_delay + 1) + pipe_section_in_samples_from_center.slice(-1)[0];

  const deltaSamples = sensor2position - sensor1position;

  const corrLength = roundFunc(deltaSamples + max_delay * 0.3);

  const corrIndexStart = max_delay - corrLength / 2; // need to be in same units (/ factor)
  const corrIndexEnd = max_delay + corrLength / 2;

  const { histogram, correlation } = generateCorrelationGraphs(band_num, corrLength, locs, pks, corrIndexStart, corrIndexEnd, factor);

  const distancesValuesArr = new Array(corrLength).fill('');

  const sensorsPositions = [sensor1position, sensor2position].map((x, ndx) => {
    const val = roundFunc((x - corrIndexStart) / factor);
    distancesValuesArr[val] = (roundFunc(pipe_len_m) * ndx).toFixed(); // for first sensor ndx = 0 then its value will be 0.
    return val;
  });
  const distancesValuesArrSensorsOnly = [...distancesValuesArr];

  const leaksData = ave_locks_in_samples
    // .filter((loc) => loc >= corrIndexStart || loc <= corrIndexEnd)
    .map((loc, index) => ({
      loc,
      dist: (ave_locks_in_m[index]).toFixed(),
    }));

  const probabilityData = leaksData
    .filter((item) => (item.loc - corrIndexStart) >= 0 && item.loc < corrIndexEnd)
    .map((item) => {
      const val = roundFunc((item.loc - corrIndexStart) / factor);
      distancesValuesArr[val] = item.dist;
      return val;
    });

  return {
    histogram,
    correlation,
    sensorsPositions,
    probabilityData,
    distancesValuesArr,
    distancesValuesArrSensorsOnly
  };
}

function generateCorrelationGraphs(band_num, corrLength, locs, pks, corrIndexStart, corrIndexEnd, factor) {
  let histogram = [];
  const corrLengthFinal = roundFunc(corrLength / factor);
  let correlation = new Array(corrLengthFinal).fill(0);

  for (let i = 0; i < band_num; i++) {
    let tmpArray = new Array(corrLengthFinal).fill(0);

    locs.forEach((valuesSet, index) => {
      const itrLoc = valuesSet[i];

      // if (ndx >= 0 && ndx < corrLengthFinal) {
      if (itrLoc >= corrIndexStart && itrLoc <= corrIndexEnd) {
        const ndx = Math.min(roundFunc((itrLoc - corrIndexStart) / factor), tmpArray.length - 1);

        const value = pks[index][i];
        // const sqrtValue = Math.sqrt(value);

        tmpArray[ndx] += value;
        // tmpArray[ndx] += sqrtValue;

        if (KAISER) {
          // if (ndx - 1 >= 0) correlation[ndx - 1] += value * KAISER_FACTOR_1;
          if (ndx - 1 >= 0) tmpArray[ndx - 1] += value * KAISER_FACTOR_1;

          // if (ndx - 2 >= 0) tmpArray[ndx - 2] += value * KAISER_FACTOR_2;

          // if (ndx + 1 < corrLengthFinal) correlation[ndx + 1] += value * KAISER_FACTOR_1;
          if (ndx + 1 < corrLengthFinal) tmpArray[ndx + 1] += value * KAISER_FACTOR_1;

          // if (ndx + 2 < corrLengthFinal) tmpArray[ndx + 2] += value * KAISER_FACTOR_2;
        }

        correlation[ndx] += value; // sum all loc's values
      }
    });
    histogram.push(tmpArray);
  }

  const improvedCorrelation = correlation.map((x, index, arr) => {
    if (index === 0) {
      return x;
    } else {
      return x + arr[index - 1];
    }
  });

  histogram = rotateMatrixClockwiseSync(histogram);

  return { histogram, correlation: improvedCorrelation };
}

export function calcDistancesInSamplesAxis(pipesSections, sectionsDistances, pipeLength, aveLocksInSamples, aveLocksInMeter, totalTime, fs, maxDelay, factor) {
  return new Promise((resolve, reject) => {
    if (pipesSections == null || sectionsDistances == null) {
      reject('no distance data');
    } else {
      const pipeHalfLengthInSamples = (totalTime * fs) / 2;
      const startIndex = roundFunc((maxDelay - pipeHalfLengthInSamples) / factor);

      const corrOriginalSize = (maxDelay * 2) + 1;
      const corrActualSize = roundFunc(corrOriginalSize / factor);

      const distanceInSamples = new Array(corrActualSize).fill('');

      const pipePositioningFactor = totalTime * fs;
      const sampleOfPipeStart = maxDelay - pipePositioningFactor;
      const sampleOfPipeEnd = maxDelay + pipePositioningFactor;

      const sensor1Position = roundFunc((sampleOfPipeStart / factor) - startIndex);
      const sensor2Position = roundFunc((sampleOfPipeEnd / factor) - startIndex);
      // distanceInSamples[sensor1Position] = 0;
      // distanceInSamples[sensor2Position] = pipeLengthM;

      if (aveLocksInSamples && aveLocksInMeter) {
        aveLocksInSamples.forEach((loc, index) => {
          const locIndex = roundFunc(loc / factor);
          distanceInSamples[locIndex] = (aveLocksInMeter[index]).toFixed();
        });
      }

      const sensorsLocs = [
        roundFunc((sensor1Position * factor) - startIndex),
        roundFunc((sensor2Position * factor) - startIndex)
      ];

      resolve({ distanceInSamples, sensorsLocs });
    }
  });
}

export function calcAudioSignals(data1, data2) {
  return new Promise((resolve, reject) => {
    if (data1 == null && data2 == null) {
      reject('no radio data');
    } else {
      resolve({
        wav1: data1,
        wav2: data2,
      });
    }
  });
}

export function generateHistoryHistograms(history, graphData, factor) {
  return new Promise((resolve, reject) => {


    const {max_delay} = graphData;
    if (max_delay == null) {
      reject('must max_delay info');
      // } else if (locs == null || pks == null) {
      //   reject('must locs & pks data');
    } else {
      const daysBack = history?.length || 0;
      const included = graphData.tensor?.included_indexes15.included || [];

      // const pksData = [];
      // const locsData = [];
      // for (let i = 0; i < daysBack; i++) {
      //   const { pks, locs } = history[i];
      //   const itrPksData = pks.map((freqData) => freqData.map((dayData) => dayData[i]));
      //   const itrLocsData = locs.map((freqData) => freqData.map((dayData) => dayData[i]));
      //   pksData.push(itrPksData);
      //   locsData.push(itrLocsData);
      // }

      const historyStack = [];

      for (let i = 0; i < daysBack; i++) {
        const historyItem = history[i];
        const { inst_pks: pks, inst_locs: locs, band_num, max_delay, pipe_section_in_samples_from_center } = historyItem.graph_data;
        
        const sensor1position = (max_delay + 1) + pipe_section_in_samples_from_center[0];
        const sensor2position = (max_delay + 1) + pipe_section_in_samples_from_center.slice(-1)[0];
  
        const deltaSamples = sensor2position - sensor1position;
  
        const corrActualLength = roundFunc(deltaSamples + max_delay * 0.3);
  
        const corrIndexStart = roundFunc(max_delay - corrActualLength / 2); // need to be in same units (/ factor)
        const corrIndexEnd = roundFunc(max_delay + corrActualLength / 2);

        const { histogram, correlation } = generateCorrelationGraphs(band_num, corrActualLength, locs, pks, corrIndexStart, corrIndexEnd, factor);

        historyStack.push({
          histogram, //: rotateMatrixClockwiseSync(histogram),
          correlation,
          date: new Date(historyItem.sample_time).getTime(),
          included: included.includes(i)
        });
      }

      resolve(historyStack);
    }
  });
}
