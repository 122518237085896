export const it = {
  Sensor_id: 'ID Sensore',
  X: '',
  a: 'A',
  ownership: 'Proprietà',
  account: 'Account',
  active: 'Attivo',
  add: 'Aggiungi',
  alert_classification: 'Classificazione Allarme',
  add_new_noise_alert: 'Aggiungi Nuovo Allarme Rumore',
  add_new_task: 'Aggiungi Nuovo Compito',
  add_sensor: 'Aggiungi Sensore',
  address: 'Indirizzo',
  adhoc_correlation: 'Correlazione Adhoc',
  advance_alerts: 'Avanzamento Allarmi',
  advance_pipes: 'Avanzamento Tubi',
  advanced: 'Avanzato',
  agc_config: 'Configurazione AGC',
  agreement: 'Accordo',
  alert: 'Allarme',
  alert_alertId: 'Allarme {alertId}',
  alert_comment: 'Commento Allarme',
  alert_id: 'ID Allarme',
  a_valid_segment: 'Un Segmento Valido',
  avg_last_correlation: 'Media Ultima Correlazione',
  avg_last_spectrogram: 'Media Ultimo Spettrogramma',
  above_under: 'Sopra / Sotto',
  above: 'Sopra',
  action: 'Azione',
  address_: 'Indirizzo: ',
  addresses_address1_address2: 'Indirizzi: {address1}, {address2}',
  alert_date: 'Data Allarme: ',
  alert_id_filter: 'Filtro ID Allarme',
  alert_id_id: 'ID Allarme {id}',
  alert_id_: 'ID Allarme: ',
  alert_id_id_: 'ID Allarme: {id}',
  id: 'ID',
  alert_status: 'Stato Allarme',
  alert_type: 'Tipo di Allarme',
  alert_alertid: 'Allarme {alertId}',
  alerts_options: 'Opzioni Allarmi',
  alerts: 'Allarmi',
  allow_false_positive: 'Permetti Falso Positivo',
  allow_prod2_run: 'Permetti Esecuzione Prod2',
  allowprod2run: 'PermettiEsecuzioneProd2',
  analyzer: 'Analizzatore',
  apply_changes: 'Applica Modifiche',
  are_you_sure_you_want_to_remove_this_device: 'Sei sicuro di voler rimuovere questo dispositivo?',
  are_you_sure_you_want_to_uninstall_the_device_and_remove_this_sensor: 'Sei sicuro di voler disinstallare il dispositivo e rimuovere questo sensore?',
  are_you_sure_you_want_to_uninstall_the_device: 'Sei sicuro di voler disinstallare il dispositivo?',
  are_you_sure_you_want_to_remove_this_sensor: 'Sei sicuro di voler rimuovere questo sensore?',
  are_you_sure: 'Sei sicuro?',
  this_action_will_rerun_override: 'Questa azione sovrascriverà i campioni precedenti',
  confirmation_success: 'Ecco una conferma che l\'azione è stata completata con successo',
  confirmation_error: 'L\'azione non è riuscita',
  assessment_id_id: 'ID Valutazione: {id}',
  assetid_asset: 'AssetID: {asset}',
  attenuation: 'Attenuazione',
  audio_recording_intensity_calculated_with_electrical_interference_filltering: 'Intensità registrazione audio calcolata con filtraggio interferenze elettriche',
  recording_time_1: 'Ora della Prima Registrazione',
  recording_time_2: 'Ora della Seconda Registrazione',
  recording_time_3: 'Ora della Terza Registrazione',
  authorization: 'Autorizzazione',
  auto_reset_at_lastautoresetdate: 'Ripristino Automatico a {lastAutoResetDate}',
  auto_reset_count_autoresetcount: 'Conteggio Ripristino Automatico {autoResetCount}',
  auto: 'Automatico',
  autoclosedate: 'Data di Chiusura Automatica',
  average_correlation_contrast: 'Contrasto Correlazione Media',
  average_correlation: 'Correlazione Media',
  average_spectrogram: 'Spettrogramma Medio',
  azbest_cement: 'Cemento Amianto',
  alert_id_param: 'ID Allarme: {id}',
  alert_state: 'Stato Allarme',
  alert_status2: 'Stato Allarme:',
  alert_statuses: 'Stati Allarme',
  all_boundaries_selected: 'Tutti i Confini selezionati',
  analyze: 'analizza',
  and_the: '” e il “',
  application_services: 'Servizi Applicativi',
  applies_only_to_us_government_licensees: '[Applicabile solo ai Licenziatari del Governo USA]',
  aquarius: 'AQUARIUS',
  archived: 'Archiviato',
  are_you_sure_you_want_to_delete_this_type: 'Sei sicuro di voler eliminare questo {type}?',
  asc: 'ASC',
  assembly: 'Assemblaggio',
  blank_space: ' ',
  asset_id: 'ID Asset',
  asset_id_param: 'ID Asset: {asset}',
  exported_to_google_sheet_successfully: 'Esportato con successo su Google Sheet',
  open_google_sheet: 'Apri Google Sheet',
  export_to_excel: 'Esporta in Excel',
  export_alerts_history: 'Esporta Cronologia Allarmi',
  export_noise_alerts_history: 'Esporta Cronologia Allarmi Rumore',
  sensors_maintenance: 'Manutenzione Sensori',
  sensors_activity_report: 'Rapporto Attività Sensori',
  sensors_technician_report: 'Rapporto Tecnico Sensori',
  sensors_admin_report: 'Rapporto Amministrativo Sensori',
  there_is_no_data_available: 'Non ci sono dati disponibili',
  no_data_available: 'Nessun dato disponibile',
  based_on_last_weeks_data: 'Basato sui dati dell\'ultima settimana',
  network_response_not_ok: 'Risposta della rete non valida',
  technician_report: 'Rapporto Tecnico',
  report_type: 'Tipo di Rapporto',
  select_customer: 'Seleziona Cliente',
  rerun_historical_data: 'Rielabora Dati Storici',
  select_time_frame: 'Seleziona Intervallo di Tempo',
  time_source: 'Fonte Temporale',
  valve_leak: 'Perdita Valvola',
  is_ultra: 'Ultra',
  rerun: 'Rielabora',
  please_select: 'Si prega di Selezionare',
  export_shp_file: 'Esporta File SHP',
  export_alerts_to_excel: 'Esporta Allarmi in Excel',
  export_all_transients_to_excel: 'Esporta Tutti i Transitori in Excel',
  hello_username: 'Ciao {userName}',
  about: 'Informazioni',
  help: 'Aiuto',
  logout: 'Disconnetti',
  licneces: 'Licenze',
  local: 'Locale',
  gmt: 'GMT',
  last_correlation: 'Ultima Correlazione',
  last_spectrogram: 'Ultimo Spettrogramma',
  radio_quality: 'Radio [dt: {dt}, qualità: {quality}]',
  please_enter_your_new_password_below: 'Si prega di inserire la nuova password qui sotto',
  enter_new_password_again: 'Inserisci di nuovo la nuova password',
  meter: 'm',
  fit: 'ft',
  not_sensitive: 'Non Sensibile',
  is_emi: 'È EMI',
  is_saturation: 'È Saturazione',
  is_delayed_wakeup: 'È Risveglio Ritardato',
  iq_100b_reports: 'Rapporti IQ100B',
  is_white_noise: 'È Rumore Bianco',
  is_overlapping: 'È Sovrapposto',
  audio: 'Audio',
  device_installation_date: 'Data Installazione Dispositivo: {deviceInstallationDate}',
  device_generation: 'Generazione Dispositivo',
  audio_clean: 'Audio Pulito',
  authorized_distributor: 'Distributore Autorizzato',
  authorized_users: 'Utenti Autorizzati',
  authorized_users_and_use_limitations: 'Utenti Autorizzati e Limitazioni d\'Uso',
  automatic_synchronization: 'Sincronizzazione Automatica',
  sync_method: 'Metodo di Sincronizzazione',
  average: 'media',
  avg_max_correlation: 'Media Massima Correlazione',
  avg_max_spectrogram: 'Media Massima Spettrogramma',
  bit_information: 'Informazioni BIT',
  bit_response_value: 'Valore Risposta BIT',
  bit_type: 'Tipo BIT',
  back: 'Indietro',
  bad_status: 'Stato Errato',
  battery_voltage_post: 'Tensione Batteria Post',
  battery_voltage_pre: 'Tensione Batteria Pre',
  battery: 'Batteria',
  bounding: 'Limitazione',
  csq: 'CSQ',
  cannot_find_your_search: 'Impossibile trovare la tua ricerca',
  cast_iron: 'Ghisa',
  change_statename_of_leak_id_from_prevstate_to_nextstate: 'Cambia {stateName} della perdita {id} da {prevState} a {nextState}',
  change_statename_of_alert_type_from_prevstate_to_nextstate: 'Cambia {stateName} del {alertType} {id} da {prevState} a {nextState}',
  circle: 'Cerchio',
  city: 'Città',
  click_to_set_current_map_position_as_project_center: 'Clicca per impostare la posizione attuale della mappa come centro del progetto',
  close_aleart: 'Chiudi Avviso',
  comm_ratio: 'Rapporto Comunicazione',
  comment: 'Commento',
  comment_date: 'Data Commento',
  communication_problem: 'Problema di Comunicazione',
  compression_enabled: 'Compressione Abilitata',
  compression: 'Compressione',

  b: 'B',
  back_to_home_view: 'Torna alla Vista Principale',
  back_to_qalertsq: 'Torna agli Allarmi',
  backfill: 'Rinterro',
  backfill_comment: 'Commento Rinterro',
  bedding_comment: 'Commento Letto di Posa',
  bedding_type: 'Tipo di Letto di Posa',
  brass: 'Ottone',

  config_record_duration: 'Durata Registrazione Configurazione',
  config_status: 'Stato Configurazione',
  consumption_time_: 'Tempo di Consumo: ',
  consumtion: 'Consumo',
  consumtion_: 'Consumo: ',
  contact_email: 'Email Contatto',
  contact_person: 'Persona di Contatto',
  contact_phone_1: 'Telefono Contatto 1',
  contact_phone_2: 'Telefono Contatto 2',
  correlation_audio: 'Audio Correlazione',
  correlation_radio: 'Radio Correlazione',
  couple_coverage: 'Copertura Coppia',
  couple_deleted_successfuly: 'Coppia Eliminata con Successo',
  couple_id_: 'ID Coppia',
  couple_id_coupleid_material_count_alerts: 'ID Coppia: {CoupleID},   Materiale: {material},   {Count} Allarmi',
  couple_id_id: 'ID Coppia: {id}',
  couple_path: 'Percorso Coppia:',
  couple_coupleid: 'Coppia {coupleId}',
  couple_id_id_: 'Coppia {id}',
  couples_days_back_for_only_updated: 'Giorni Indietro per Coppie Solo Aggiornate',
  create_couple: 'Crea Coppia',
  create_task: 'Crea Compito',
  create_new_customer: 'Crea Nuovo Cliente',
  create_new_project: 'Crea Nuovo Progetto',
  create_new_sop: 'Crea Nuovo SOP',
  create_new_user: 'Crea Nuovo Utente',
  create_new_area_of_interest: 'Crea Nuova Area di Interesse',
  creation_time: 'Tempo di Creazione',
  customer_id_: 'ID Cliente: ',
  customer_name: 'Nome Cliente',
  customer_customer: 'Cliente: {customer}',
  customer_name_: 'Cliente: {name}',
  customers: 'Clienti',
 c: 'C',
  cancel: 'Annulla',
  yes: 'Sì',
  changed: 'Modificato',
  choice_of_law_and_forum: 'Scelta della Legge e del Foro',
  choose_location_for_new_installation_point: 'Scegli la posizione per il nuovo Punto di Installazione',
  claims: 'Reclami',
  clarity: 'Chiarezza:',
  english: 'Inglese',
  hebrew: 'Ebraico',
  layer_manager: 'Gestione Livelli',
  satellite_view: 'Vista Satellitare',
  show_alerts: 'Mostra Allarmi',
  minimal_noise_intensity: 'Intensità Rumore Minima',
  filtered_noise_intensity: 'Intensità Rumore Filtrata',
  show_mobile_samples: 'Mostra Campioni Mobili',
  show_mobile_alerts: 'Mostra Allarmi Mobili',
  show_sensors: 'Mostra Sensori',
  show_installation_points: 'Mostra Punti di Installazione',
  show_areas_of_interest: 'Mostra Aree di Interesse',
  pipes_by_material: 'Tubi per Materiale',
  pipes_by_diameter: 'Tubi per Diametro',
  pipes_by_pressure: 'Tubi per Pressione',
  pipes_by_placement_year: 'Tubi per Anno di Installazione',
  couples_coverage: 'Copertura Coppie',
  show_couples_path: 'Mostra Percorso Coppie',
  project_boundaries: 'Confini del Progetto',
  show_water_meter_layer: 'Mostra Strato Contatori d’Acqua',
  project_coverage: 'Copertura del Progetto',
  email: 'Email',
  password: 'Password',
  forgot_password: 'Password Dimenticata',
  login: 'Accedi',
  manual_reset_at: 'Ripristino Manuale a {lastManualResetDate}',
  manual_reset_count: 'Conteggio Ripristino Manuale {manualResetCount}',
  auto_reset_at: 'Ripristino Automatico a {lastAutoResetDate}',
  auto_reset_count: 'Conteggio Ripristino Automatico {autoResetCount}',
  sensors_activity: 'Attività Sensori',
  select_month: 'Seleziona Mese',
  boundary: 'Confine',
  include_archived_sensors: 'Includi Sensori Archiviati',
  all: 'Tutti',
  sensor_1: 'Sensore 1',
  sensor_2: 'Sensore 2',
  device: 'Dispositivo',
  pressure: 'Pressione',
  g5_status: 'Stato G5',
  classification: 'Classificazione',
  clear_selection: 'Cancella Selezione',
  click_on_map_to_set_coordinate: 'Clicca sulla Mappa per impostare la Coordinata',
  complete_alert_details_and_save: 'Completa i Dettagli dell’Allarme e Salva',
  click_to_download: 'Clicca per Scaricare',
  close: 'Chiudi',
  close_noise_alert: 'Chiudi Allarme Rumore',
  close_valve_alert: 'Chiudi Allarme Valvola',
  closure_date: 'Data di Chiusura:',
  code: 'Codice',
  collectively_the: '”, collettivamente, il “',
  commands: 'Comandi',
  commented: 'Commentato: ',
  comments: 'Commenti',
  communications_service: 'Servizio di Comunicazione',
  compaction: 'Compattazione',
  compliance_with_laws: 'Conformità alle Leggi',
  concrete: 'Cemento',
  config: 'Configurazione',
  config_duration: 'Durata Configurazione',
  configuration: 'Configurazione',
  configure: 'Configura',
  consent_to: 'Consenso a',
  consent_to_use_of_data: 'Consenso all’Uso dei Dati',
  consumption: 'Consumo:',
  consumption_time: 'Tempo di Consumo:',
  contact_details: 'Dettagli Contatto',
  conten: 'Conten',
  content: 'Contenuto',
  contrast: 'Contrasto',
  control_buttons: 'Pulsanti di Controllo',
  coordinate: 'Coordinata',
  copper: 'Rame',
  copyright_2021_aquarius_spectrumbrbr_all_rights_re: 'Copyright © 2021 Aquarius Spectrum Tutti i diritti riservati. Aquarius Spectrum, Greenwork Yakum, Edificio A 1° piano, Yakum, Israele, Casella Postale 6097200, Israele Telefono: +972-74-7136644 Fax: +972-4-6709014',
  corr_interval: 'Intervallo Correlazione',
  corr_num_samples: 'Numero Campioni Correlazione',
  correlation: 'Correlazione',
  corrosion_type: 'Tipo di Corrosione',
  cost: 'Costo:',
  count: 'Conteggio',
  couple_and_id: 'Coppia {id}:',
  couple_assessment_report: 'Rapporto Valutazione Coppia',
  couple_id: 'ID Coppia',
  couple_id2: 'Coppia {coupleId}:',
  couple_length: 'Lunghezza Coppia',
  couple_comment: 'Commento Coppia',
  couples: 'Coppie',
  couples_options: 'Opzioni Coppie',
  crack_diameter_inches: 'Diametro Crepa (pollici)',
  create: 'Crea',
  create_a_new_noise_alert: 'Crea un Nuovo Allarme Rumore',
  create_a_new_valve_alert: 'Crea un Nuovo Allarme Valvola',
  create_date: 'Data Creazione',
  create_new_installation_point: 'Crea un Nuovo Punto di Installazione',
  created_new_alert: 'Nuovo Allarme Creato',
  current_device_id: 'ID Dispositivo Attuale',
  current_status: 'Stato Attuale',
  customer: 'Cliente',
  customer_account: 'Account Cliente',
  customer_customerParam: 'Cliente: {customer}',
  flow_param: 'Flusso: {flow}',
  from_date_date: 'Da Data: {date}',
  amplitude: 'Ampiezza',
  file: 'File',
  min_rms: 'Min RMS',
  catm_flags: 'catm_flags',
  hw_v: 'HW V',
  classify: 'Classifica',
  min: 'min',
  nc_3: 'nc_3',
  threshold: 'Soglia',
  contact_name: 'Nome Contatto',
  hi: 'Ciao',
  assambly: 'Assemblaggio',
  g5_intensity: 'Intensità G5',
  comm_messages: 'Messaggi Comunicazione',
  installation_point: 'Punto di Installazione',
  bit_samples: 'Campioni Bit',
  bit_messages: 'Messaggi Bit',
  bit: 'Bit',
  gain: 'Guadagno',
  download: 'Scarica',
  seconds: 'Secondi',
  freq: 'Freq',
  noise: 'Rumore',
  low: 'Basso',
  radio_clean: 'Radio Pulita',
  power: 'Potenza',
  radio_frequency_data: 'Dati Frequenza Radio',
  noise_intensity_min_band_power: 'Intensità Rumore Potenza Minima Banda',
  noise_sampling_taken_during_audio: 'Campionamento Rumore effettuato durante la registrazione audio dal sensore',
  audio_data_size: 'Dimensione Dati Audio',
  request_time: 'Tempo Richiesta',
  response_time: 'Tempo Risposta',
  selected_fm_channel: 'Canale FM Selezionato',
  tp_avg: 'TP Medio',
  tp_rms: 'TP RMS',
  std_agc: 'STD AGC',
  std_agc_2: 'STD AGC 2',
  tp_rms_2: 'TP RMS 2',
  original: 'Originale',
  audio_gain: 'Guadagno Audio',
  imsi: 'IMSI',
  batt: 'Batt',
  acc_tech: 'Tecnologia Acc',
  tech: 'Tecnologia',
  nbiot_flags: 'nbiot_flags',
  nbiot_tx_tout: 'nbiot_tx_tout',
  nbiot_rx_tout: 'nbiot_rx_tout',
  nbiot_tx_power: 'nbiot_tx_power',
  nbiot_tx_gain: 'nbiot_tx_gain',
  nbiot_packet: 'nbiot_packet',
  catm_packet: 'catm_packet',
  catm_rx_tout: 'catm_rx_tout',
  catm_tx_tout: 'catm_tx_tout',

  rsrq_db: 'RSRQ [db]',
  rsrp_db: 'RSRP [db]',
  hw_rev: 'Revisione HW',
  nb: 'NB',
  unknown: 'Sconosciuto',
  cat_m: 'Cat-M',
  fw_rev: 'Revisione FW',
  fw_v: 'FW V.',
  rst: 'RST',
  orig_2: 'Orig 2',
  technology: 'Tecnologia',
  cell_id: 'ID Cellulare',
  med: 'Medio',
  high: 'Alto',
  customer_id: 'ID Cliente:',
  customer_indemnification: 'Indennizzo Cliente',
  dmas_layer: process.env.REACT_APP_DMA_TITLE,
  dates: 'Date',
  days_correlation_incative: 'Giorni di Correlazione Inattiva',
  days_inactive: 'Giorni Inattivi',
  decive_type: 'Tipo di Dispositivo',
  delete_failed: 'Eliminazione Fallita',
  device_id_: 'ID Dispositivo: ',
  device_id_device: 'ID Dispositivo: {device}',
  device_installation: 'Installazione Dispositivo',
  device_already_installed_in_project_project: 'Il dispositivo è già installato nel Progetto {project}',
  diameter_placement_year: 'Diametro / Anno di Installazione',
  dist_m: 'Distanza [m]',
  do_not_take_0_samples_in_average: 'Non Considerare 0 Campioni nella Media',
  done: 'Fatto',
  download_audio: 'Scarica Audio',
  download_log_file: 'Scarica File di Log',
  draw_mode: 'Modalità Disegno',
  dual_signal: 'Segnale Doppio',
  d: 'D',
  dashboard: 'Cruscotto',
  date: 'Data',
  day: 'Giorno',
  days_detected: 'Giorni Rilevati:',
  delete: 'Elimina',
  delete_type_id: 'Elimina {type} {id}',
  deletion_of_customer_data: 'Eliminazione dei Dati del Cliente',
  deletion_of_data: 'Eliminazione dei Dati',
  desc: 'DESC',
  design_operating_press: 'Pressione Operativa di Progetto',
  details: 'Dettagli',
  detected: 'Rilevato',
  device_configuration_messages_logs: 'Log Messaggi Configurazione Dispositivo',
  device_id: 'ID Dispositivo',
  device_id_val: 'Dispositivo {id}',
  mobile_alert_id_val: 'Allarme Mobile {id}',
  frequency_hz: 'Frequenza: {freq} [Hz]',
  duration_sec: 'Durata: [sec]',
  access_point: 'Punto di Accesso',
  fill_addresses: 'Compila Indirizzi',
  task_samples: 'Campioni Compito',
  samples_count: 'Conteggio Campioni',
  creation_date_default: 'Data Creazione (predefinita)',
  tasks: 'Compiti',
  tasks_options: 'Opzioni Compiti',
  tasks_filters: 'Filtri Compiti',
  alerts_filters: 'Filtri Allarmi',
  creation_date: 'Data Creazione',
  survey: 'Indagine',
  devices: 'Dispositivi',
  clean: 'Pulito',
  raw: 'Non Elaborato',
  low_intensity: 'Bassa Intensità',
  user_name: 'Nome Utente',
  duration: 'Durata',
  intensity_raw: 'Intensità [non elaborata]',
  intensity_db: 'Intensità: [dB]',
  intensity: 'Intensità',
  battery_level: 'Livello Batteria',
  terms_and_conditions: 'Termini e Condizioni',
  battery_post: 'Batteria Post',
  fw_version: 'Versione FW',
  hw_version: 'Versione HW',
  rsrq: 'RSRQ',
  rsrp: 'RSRP',
  rssi: 'RSSI',
  snr: 'SNR',
  noise_gain: 'Guadagno Rumore',
  noise_min_rms: 'Rumore Min RMS',
  normalize_noise: 'Normalizza Rumore',
  by_clicking_login_you_agree_to_our: 'Cliccando su "Accedi", accetti i nostri',
  fm_radio_channel: 'Canale Radio FM',
  and_our: 'e i nostri',

  get_audio_config: 'Ottieni Configurazione Audio',
  get_time_zone: 'Ottieni Fuso Orario',
  get_lte_mode: 'Ottieni Modalità LTE',
  get_server: 'Ottieni Server',
  get_fm_channel: 'Ottieni Canale FM',
  start_fm_scan: 'Avvia Scansione FM',
  get_apn: 'Ottieni APN',
  get_valve_leak: 'Ottieni Perdita Valvola',
  get_agc_conf: 'Ottieni Configurazione AGC',
  get_fallback_server: 'Ottieni Server Alternativo',
  get_capability: 'Ottieni Capacità',
  get_lte_configuration: 'Ottieni Configurazione LTE',
  bit_request: 'Richiesta BIT',
  activate_toolkit: 'Attiva Toolkit',
  you_are_about_to_take_a_significant_action: 'Stai per eseguire un’azione significativa',
  sure_you_want_to_deactivate_sensors: 'Sei sicuro di voler disattivare i sensori?',
  privecy_policy: 'Informativa sulla Privacy',
  confirm_updating_devices_server: 'Conferma aggiornamento server dispositivi',
  confirm_updating_devices_as_version: 'Conferma aggiornamento dispositivi alla versione "{version}"',
  get_scheduler: 'Ottieni Scheduler',
  discard: 'Scarta',
  edit: 'Modifica',
  deactivate: 'Disattiva',
  no_leak: 'Nessuna Perdita',
  suspected_fast_developing_leak: 'Sospetta Perdita in Rapido Sviluppo',
  suspected_leak: 'Sospetta Perdita',
  uncertain: 'Incerto',
  undefined: 'Non Definito',
  hydrant: 'Idrante',
  sample_intensity: 'Intensità Campione',
  weighted_intensity: 'Intensità Ponderata',
  low_rm: 'RM basso: [{rm}]',
  prob: 'Probabilità',
  show_in_5g: 'Mostra in 5G',
  draw_sensors_selection_area: 'Disegna Area di Selezione Sensori',
  sensors_selected: '{num} Sensori Selezionati',
  last_sample: 'Ultimo Campione:',
  couple_length_units: 'Lunghezza Coppia: {coupleLength}[{units}]',
  diameter_or_placement_year: 'Diametro / Anno di Installazione',
  disclaimer: 'Disclaimer',
  distance: 'Distanza:',
  distance_between_sensors: 'Distanza Tra i Sensori:',
  distance_calculation: 'Calcolo Distanza:',
  distance_from_sensor1: 'Distanza dal Sensore 1:',
  distances: 'Distanze',
  diameter_inch: 'Diametro Pollici',
  distribution_list: 'Lista di Distribuzione:',
  download_failed_file_not_found: 'Download fallito - file non trovato',
  download_sensor_audio: 'Scarica Audio Sensore',
  ductile_iron: 'Ghisa Duttile',
  end_user: 'UTENTE FINALE',
  enter_an_mfa_code_to_complete_signin: 'Inserisci un codice MFA per completare l’accesso.',
  expand_row: 'espandi riga',
  export2: 'Esporta',
  export_the_view_to_PDF: 'Esporta la vista in PDF',
  epsg: 'EPSG',
  edit_distributorname_projects: 'Modifica Progetti {distributorName}:',

  end_frequency: 'Frequenza Finale',
  end_frequency_hz: 'Frequenza Finale (Hz)',
  end_frequncy: 'Frequenza Finale',
  end_record_time: 'Tempo di Fine Registrazione',
  end_time: 'Ora di Fine',
  estimated_address: 'Indirizzo Stimato',
  event_cause: 'Causa Evento',
  event_date: 'Data Evento',
  event_information: 'Informazioni Evento',
  event_max_value: 'Valore Massimo Evento',
  event_min_value: 'Valore Minimo Evento',
  event_nature: 'Natura Evento',
  exclude_sample_1_by_4_flag: 'Escludi Campione 1 su 4 flag',
  exclude_sample_1_by_4_number: 'Escludi Campione 1 su 4 numero',
  exclude_sample_1_by_4_radius: 'Escludi Campione 1 su 4 raggio',

  export_all_projects_to_excel: 'Esporta Tutti i Progetti in Excel',

  iq100b_reports: 'Rapporti iQ100B',
  export_the_view_to_pdf: 'Esporta la vista in PDF',
  export: 'Esporta',
  ftpend_send_time: 'FTPEndSendTime',
  ftpfile_size: process.env.REACT_APP_FTP_SIZE_TEXT,
  file_size: 'Dimensione File',
  ftpsend_duration_sec: 'Durata Invio FTP (sec)',
  ftpstart_send_time: 'FTPStartSendTime',
  fading_alert: 'Allarme in Dissolvenza',
  false_path: 'Percorso Falso',
  fast_development: 'Sviluppo Rapido',
  file_name: 'Nome File',
  filtered_avg: 'Media Filtrata',
  filtered_intensity_avg: 'Media Intensità Filtrata',
  filtered_noise: 'Rumore Filtrato',
  filtered_audio_recordings_intensity_calculations_avarage: 'Media Calcolo Intensità Registrazioni Audio Filtrate',
  filters_: 'Filtri:',
  firmware_version: 'Versione Firmware',
  first_name: 'Nome',
  fixed_leaks: 'Perdite Riparate',
  flow_flow: 'Flusso: {flow}',
  fmax: 'Fmax',
  fragmented_file: 'File Frammentato',
  frequency_histogram: 'Istogramma Frequenza',
  failed: 'Fallito',
  fees: 'Tariffe',
  files: 'File',
  filter: 'Filtro',
  filters: 'Filtri',
  first_installation_date: 'Data Prima Installazione',
  fm2_receiver: 'Ricevitore FM2',
  fm_receiver: 'Ricevitore FM',
  fmin: 'fmin',
  frequency: 'Frequenza',
  from: 'Da:',
  from_to: 'Da - A',
  frost_depth: 'Profondità del Gelo',
  fs: 'fs',
  go: 'Vai',
  gps_ratio: 'Rapporto GPS',
  generate: 'Genera',
  good_status: 'Stato Buono',
  fast_growing_alert: 'Allarme a Crescita Rapida',
  slow_growing_alert: 'Allarme a Crescita Lenta',
  guidelines_: 'Linee Guida:',
  hardware_version: 'Versione Hardware',
  high_severity_more_than_4_leaks: 'Alta Gravità (più di 4 perdite)',
  high_frq: 'Alta Frq',
  mid_frq: 'Media Frq',
  low_frq: 'Bassa Frq',
  inactive: 'Inattivo',
  index_band_to_send: 'Banda Indice da Inviare',
  index_: 'Indice: ',
  infrastructure: 'Infrastruttura',
  install_date: 'Data Installazione',
  installation_point_comment: 'Commento Punto di Installazione',
  intensity_not_filtered: 'Intensità (Non Filtrata)',
  intensity_: 'Intensità: ',
  intensity_intensity: 'Intensità: {intensity}',
  area_of_interest_comment: 'Commento Area di Interesse',
  area_of_interest_id_id: 'Area di Interesse {id}',
  area_of_interest_type: 'Tipo di Area di Interesse',
  area_of_interest: 'Area di Interesse',
  areas_of_interest: 'Aree di Interesse',
  edit_area_of_interest: 'Modifica Area di Interesse',
  is_csq_ok: 'CSQ OK',
  is_display_city_column: 'Visualizza Colonna Città',
  is_elecrolysis: 'È Elettrolisi',
  is_ftp_ok: process.env.REACT_APP_FTP_TEXT,
  is_modem_closed_ok: 'Modem Chiuso OK',
  is_send_file_ok: 'File Inviato OK',
  is_ok: 'OK',
  log: 'LOG',
  last_event_date: 'Data Ultimo Evento',
  last_name: 'Cognome',
  last_run_days: 'Ultima Esecuzione (giorni)',
  last_sample_: 'Ultimo Campione: ',
  last_uninstall_reason: 'Motivo Ultima Disinstallazione',
  lasteventfrequency: 'Frequenza Ultimo Evento',
  leak_per_units: 'Perdita per {units}',
  leakgrowth: 'Crescita Perdita',
  leakperkm: 'Perdita per Km',
  leaks_infrastructure: 'Perdite / Infrastruttura',
  leaks_by_diameter: 'Perdite per Diametro',
  leaks_by_materials_diameter_for_pipe_placement_year: 'Perdite per Materiali & Diametro per Anno di Installazione',
  leaks_by_materials_diameter_vs_pipe_placement_year: 'Perdite per Materiali & Diametro vs Anno di Installazione',
  leaks_by_materials: 'Perdite per Materiali',
  leaks_by_pipe_placement_year: 'Perdite per Anno di Installazione Tubi',
  leaks_by_years: 'Perdite per Anni',
  leaks: 'Perdite',
  legend: 'Legenda',
  linked_couples: 'Coppie Collegate',
  list_name: 'Nome Elenco',
  log_information_device_deviceid: 'Informazioni Log. Dispositivo: {deviceId}',
  low_severity_1_leak: 'Bassa Gravità (1 perdita)',
  low_severity_with_fast_development_alert: 'Bassa Gravità con Allarme di Sviluppo Rapido',
  mail: 'Email',
  main: 'Principale',
  manage_distributor: 'Gestisci Distributore',
  manual_reset_at_lastmanualresetdate: 'Ripristino Manuale a {lastManualResetDate}',
  manual_reset_count_manualresetcount: 'Conteggio Ripristino Manuale {manualResetCount}',
  manual: 'Manuale',
  map_alerts: 'Mappa Allarmi',
  map_pipes_diameter_type: 'Mappa Tubi Diametro Tipo',
  material_diameter_placement_year: 'Materiale & Diametro / Anno di Installazione',
  material_placement_year: 'Materiale / Anno di Installazione',
  material_name: 'Nome Materiale',
  medium_severity_2_4_leaks: 'Gravità Media (2 - 4 perdite)',
  meteric_feet_epsg_only: 'Metrici / Piedi EPSG Solo',
  min_nc1_days: 'Giorni Min NC1',
  min_noise: 'Rumore Minimo',
  min_rms_nc1: 'Min RMS NC1',
  minimal_intensity: 'Intensità Minima',
  minimal_noise_sampling_taken_by_the_sensor_prior_to_audio_recording: 'Campionamento Rumore Minimo effettuato dal sensore prima della registrazione audio',
  modem_atenna_signal_strength: 'Potenza Segnale Antenna Modem',
  modem_usage: 'Utilizzo Modem',
  more_samples: 'Altri Campioni',
  most_problematic_pipes_based_on_leaks_count: 'Tubi Più Problematici Basati sul Numero di Perdite',
  most_problematic_pipes_based_on_leaks_per_units: 'Tubi Più Problematici Basati su Perdite per {units}',
  multi_diameter: 'Diametro Multiplo',
  multi_material: 'Materiale Multiplo',
  nc3_std_mean_ratio: 'Rapporto Media Std NC3',
  nc3_proj_mean: 'Media Proiettata NC3',
  name_: 'Nome: ',
  new_customer: 'Nuovo Cliente',
  new_list_name: 'Nuovo Nome Elenco',
  new_project: 'Nuovo Progetto',
  no_leaks: 'Nessuna Perdita',
  nois_eaudio_duration_ms: 'Durata Audio Rumoroso MS',
  noise_alert_const_prob_thr: 'Soglia Probabilità Costante Allarme Rumore',
  noise_alert_step_prob_thr: 'Soglia Probabilità Graduale Allarme Rumore',
  noise_audio_fs: 'Frequenza Audio Rumore',
  noise_audio_gain: 'Guadagno Audio Rumore',
  noise_intensity_gain: 'Guadagno Intensità Rumore',
  noise_ok_threshold: 'Soglia OK Rumore',
  noise_sample: 'Campione Rumore',
  noise_samples: 'Campioni Rumore',
  noise_sync: 'Sincronizzazione Rumore',
  noise_level_: 'Livello Rumore',
  noise_sampling_taken_by_the_sensor_during_audio_recording: 'Campionamento Rumore effettuato dal sensore durante la registrazione audio',
  noise_value_val: 'Valore Rumore: {val}',
  non_associated: 'Non Associato',
  not_active: 'Non Attivo',
  not_installed: 'Non Installato',
  num_coefficients: 'Numero Coefficienti',
  num_frequency_bands: 'Numero Bande di Frequenza',
  numbadburstsamples_thr: 'Soglia Campioni Scoppio Difettosi',
  number_of_samples_: 'Numero di Campioni:',
  number_of_transients: 'Numero di Transitori',
  open_assessment: 'Apri Valutazione',
  open_new_leak_on_0_sample: 'Apri Nuova Perdita su 0 Campioni',
  open_projects: 'Apri Progetti',
  open_reports: 'Apri Report AQS',
  open: 'Apri',
  overground_acc_gps: 'Acc GPS Superficie',
  overground_acc_rf: 'Acc RF Superficie',
  overground_hyd_gps: 'Hyd GPS Superficie',
  overground_hyd_rf: 'Hyd RF Superficie',
  phone: 'Telefono',
  pipe_accuracy: 'Accuratezza Tubo',
  pipe_assessment_report: 'Rapporto Valutazione Tubo',
  pipe_assessment: 'Valutazione Tubo',
  pipe_assessments_report_projectname_epsg: 'Rapporto Valutazioni Tubo. {projectName} [EPSG: {epsg}]',
  pipe_assessments: 'Valutazioni Tubi',
  pipe_condition_analyzer: 'Analizzatore Condizione Tubo',
  pipe_length_: 'Lunghezza Tubo',
  pipe_: 'Tubo: ',
  pipes_by_assessment: 'Tubi per Valutazione',
  pipes_by_presure: 'Tubi per Pressione',
  pipes_high_severity_analysis: 'Analisi Alta Gravità Tubi:',
  pipes_by_placement_year_: 'Tubi per Anno di Installazione',
  pipes_by_diameter_: 'Tubi per Diametro',
  pipes_by_materials: 'Tubi per Materiali',
  pipes_by_presure_: 'Tubi per Pressione',
  pipes_severity_score: 'Punteggio Gravità Tubi',
  pipes: 'Tubi',
  placement_year: 'Anno di Installazione',
  polyethelen: 'Polietilene',
  polygon: 'Poligono',
  poor_condition_pipe: 'Tubo in Cattive Condizioni',
  position_: 'Posizione: ',
  possible_pumps: 'Possibili Pompe',
  pump: 'Pompa',
  prv: 'PRV',
  washout: 'Svuotamento',
  watering: 'Irrigazione',
  fountain: 'Fontana',
  possible_ufr: 'Possibile UFR',
  pressure_value_coefficient: 'Coefficiente Valore Pressione',
  pressure_y_value_coefficient: 'Coefficiente Valore Y Pressione',
  print: 'Stampa',
  prob_irrig: 'Probabilità Irrigazione',
  prob4_burst: 'Probabilità 4 Scoppio',
  prob4_consumption: 'Probabilità 4 Consumo',
  processing: 'Elaborazione',
  profiler: 'Profilatore',
  proj_int_factor: 'Fattore Intensità Progetto',
  proj_int_len_factor: 'Fattore Lunghezza Progetto',
  project_name: 'Nome Progetto',
  project_name_: 'Progetto: {name}',
  projects_coverage: 'Copertura Progetti',
  projects: 'Progetti',
  quality_1: 'Qualità 1',
  quality_2: 'Qualità 2',
  quality_: 'Qualità: ',
  quiality_1: 'Qualità 1',
  quiality_2: 'Qualità 2',
  quiality: 'Qualità',

  g5_devices: 'Dispositivi G5',
  g5_devices_table: 'tabella dispositivi G5',
  g5_logs_table: 'tabella log G5',
  general: 'Generale',
  general_description: 'Descrizione Generale',
  general_information: 'Informazioni Generali',
  general_provisions: 'Disposizioni Generali',
  generate_report: 'Genera Report',
  generation: 'Generazione',
  get_project_defaults: 'Ottieni Impostazioni Predefinite Progetto',
  grant_of_rights: 'Concessione di Diritti',
  graph: 'Grafico',
  ground_type: 'Tipo di Terreno',
  guidelines: 'Linee Guida:',
  hidden_div_for_generate_plotly_image: 'DIV NASCOSTO PER GENERARE IMMAGINE PLOTLY',
  historical_information: 'Informazioni Storiche',
  history: 'Storia',
  id: 'ID',
  id_default: 'ID (predefinito)',
  images: 'Immagini',
  img: 'img',
  in_these_tos: 'in questi TOS',
  include_map: 'Includi Mappa',
  indemnification: 'Indennizzo',
  indemnity: 'Indennità',
  index: 'Indice',
  install: 'Installa',
  install_device: 'Installa Dispositivo',
  installation_point_id: 'ID Punto di Installazione {id}',
  installation_point_id_2: 'ID Punto di Installazione',
  installation_points: 'Punti di Installazione',
  instance_distances: 'distanze istanza',
  instance_dts: 'istanza dTs',
  intellectual_property_rights: 'Diritti di Proprietà Intellettuale',
  intensities: 'Intensità',
  diameter: 'Diametro',
  intensity_param: 'Intensità: {intensity}',
  add_alert: 'Aggiungi Allarme',
  create_alert: 'Crea Allarme',
  audio_alert_3: 'Allarme Audio 3',
  audio_base_1: 'Audio Base 1',
  audio_base_2: 'Audio Base 2',
  audio_sample: 'Campione Audio',
  audio_sample_clean: 'Campione Audio-Pulito',
  audio_base_1_clean: 'Audio Base 1-Pulito',
  audio_base_2_clean: 'Audio Base 2-Pulito',
  audio_alert_3_clean: 'Allarme Audio 3-Pulito',
  time_stamp: 'Data/Ora',
  alert_3: 'Allarme 3',
  base_1: 'Base 1',
  base_2: 'Base 2',
  event_rms: 'RMS Evento',
  base_mean: 'Media Base',
  base_std: 'STD Base',
  samples_mean: 'Media Campioni',
  samples_std: 'STD Campioni',
  event_gain: 'Guadagno Evento',
  samples_gain: 'Guadagno Campioni',
  base_magnitude: 'Magnitudo Base',
  samples_magnitude: 'Magnitudo Campioni',
  factor_baseline_rms: 'Fattore RMS',
  alert_samples: 'Campioni Allarme',
  sensor_history: 'Storia del Sensore',
  sample_distance: 'Distanza Campione',
  sample_distances: 'Distanze Campioni',
  weighted_distance: 'Distanza Ponderata',
  peak_0: 'Picco 0 [{ndx}, {maxProb}]',
  peak_1: 'Picco 1 [{ndx}, {maxProb}]',
  peak_2: 'Picco 2 [{ndx}, {maxProb}]',
  send_scheduler_message: 'Invia Messaggio Scheduler',
  type_param: 'Tipo: {type}',
  type: 'Tipo',
  assessment_param: 'ID Valutazione: {id}',
  area_of_interest_id: 'Area di Interesse {id}',
  users_list: 'Lista Utenti',
  select_all_users: 'Seleziona Tutti gli Utenti',
  create_report: 'Crea Report',
  save_as_pdf: 'Salva come PDF',
  iq_100b_mobile: 'IQ100B - Mobile',
  device_id_param: 'ID Dispositivo: {device}',
  detected_date_date: 'Data Rilevamento: {date}',
  pipe_install: 'Installazione Tubo',
  closure_date_date: 'Data Chiusura: {date}',
  fixed_date_date: 'Data Riparazione: {date}',
  event_date_param: 'Data Evento: {date}',
  status_state_param: 'Stato: {state}',
  status_param: 'Stato: {status}',
  alert_type_param: 'Tipo di Allarme: {alertType}',
  noise_alert_id_noiseId: 'ID Allarme Rumore: {noiseId}',
  comment_comment: 'Commento: {comment}',
  end_date: 'Data di Fine',
  couple_id_param: 'ID Coppia: {id}',
  probability_param: 'Probabilità: {probability}',
  leak_boss_param: 'Capo Perdita: {boss}',
  intensity2: 'Intensità:',
  interferences: 'Interferenze',
  interference_artifact: 'Artefatto Interferenza',
  is_electrolysis: 'È Elettrolisi',
  is_pressure: 'È Pressione',
  is_mini: 'È G5mini',
  is_previous_break_reported: 'È Stato Segnalato un Precedente Guasto',
  is_rocks: 'Sono Rocce',
  is_voids: 'Sono Vuoti',
  items: 'Elementi',

  lamppost_number: 'Numero Lampione',
  large_diameter: 'Diametro Grande',
  last_comment: 'Ultimo Commento:',
  last_communication: 'Ultima Comunicazione',
  last_event_time: 'Ora Ultimo Evento',
  last_installation_date: 'Data Ultima Installazione',
  latitude: 'Latitudine',
  launch_street_view_for_selected_item: 'Avvia Street View per l’elemento selezionato',
  lead: 'Piombo',
  leak_growth: 'Crescita Perdita',
  leak_id: 'ID Perdita',
  leak_id2: 'ID Perdita:',
  length: 'Lunghezza',
  license: 'Licenza',
  limitation_of_liability: 'Limitazione di Responsabilità',
  limitation_of_liability2: 'LIMITAZIONE DI RESPONSABILITÀ',
  limitations: 'Limitazioni',
  limitations_on_use: 'Limitazioni d’Uso',
  loading: 'Caricamento...',
  location_information: 'Informazioni sulla Posizione',
  logs: 'Log',
  longitude: 'Longitudine',
  longitude_latitude: 'Longitudine, Latitudine:',
  mail_sms_notification: 'Notifica Email / SMS',
  manage_distribution_lists: 'Gestisci Liste di Distribuzione',
  management: 'Gestione',
  map_could_not_be_captured_please_take_a_screenshot: 'La mappa non può essere catturata. Si prega di fare uno screenshot manualmente.',
  material: 'Materiale',
  material_and_diameter_or_placement_year: 'Materiale & Diametro / Anno di Installazione',
  material_or_placement_year: 'Materiale / Anno di Installazione',
  materials: 'Materiali',
  max_probability: 'Probabilità MASSIMA',
  mdpe: 'MDPE',
  mfa_enabled: 'MFA Abilitato',
  metal: 'Metallo',
  mfa_code: 'Codice MFA:',
  mobile: 'Mobile',
  modification_of_tos: 'Modifica dei TOS',
  month: 'Mese',
  multifactor_authentication: 'Autenticazione a più Fattori',
  n: 'N',
  min_value: 'Valore Minimo',
  max_value: 'Valore Massimo',
  median_value: 'Valore Mediano',
  name: 'Nome',
  name_correlation: 'Correlazione {name}',
  nature_of_application_hosting: 'Natura dell’Applicazione &amp; Hosting',
  nature_of_break_comment: 'Commento Natura della Rottura',
  network_coverage_gnss_satellites_interruption_of_s: 'Copertura di Rete, Satelliti GNSS, Interruzione del Servizio',
  new_couple: 'Nuova Coppia',
  new_list_item: 'Nuovo Elemento Lista',
  no_data: 'NESSUN DATO',
  no_files_uploaded_yet: 'Nessun file caricato finora...',
  no_filters_applyed: 'Nessun filtro applicato',
  no_lists: 'Nessuna Lista',
  noise_NC1_NC2: 'Rumore NC1/NC2',
  noise_alert_options: 'Opzioni Allarme Rumore',
  noise_alert_statuses: 'Stati Allarme Rumore',
  noise_interval: 'Intervallo Rumore',
  noise_level: 'Livello Rumore',
  valve_alert_options: 'Opzioni Allarme Valvola',
  control_valve: 'Valvola di Controllo',
  service_valve: 'Valvola di Servizio',
  gate_valve: 'Valvola a Saracinesca',
  air_valve: 'Valvola d’Aria',
  manhole: 'Tombino',
  fitting: 'Giunto',
  signals: 'Segnali',
  tensors: 'Tensori',
  previous_samples: 'Campioni Precedenti',
  fmReceiverSN: 'Ricevitore FM SN',
  hardwareRevision: 'Revisione Hardware',
  domainName: 'Nome Dominio',
  at_least_length_characters_are_required: 'Sono richiesti almeno {length} caratteri',
  at_least_one_uppercase_letter: 'Richiedi Lettere Maiuscole',
  at_least_one_lowercase_letter: 'Richiedi Lettere Minuscole',
  at_least_one_number: 'Richiedi Numeri',
  the_passwords_are_not_the_same: 'Le password non corrispondono',
  invalid_password: 'Password Non Valida',
  back_to_signin_page: 'Torna alla pagina di Accesso',
  forgot_your_password: 'Hai dimenticato la password?',
  email_to_reset_password: 'Inserisci la tua Email qui sotto e ti invieremo un messaggio per reimpostare la password',
  email_to_reset_password_sent: 'Abbiamo inviato un codice di reimpostazione password via Email a {mail}. Inseriscilo qui sotto per reimpostare la password.',
  reset_my_password: 'Reimposta la mia password',
  new_password: 'Nuova Password',
  confirm_new_password: 'Inserisci Nuova Password di Nuovo',
  change_password: 'Cambia Password',
  alert_information: 'Informazioni Allarme',
  created_date: 'Data di Creazione',
  raw_intensity: 'Intensità Grezza',
  filtered_intensity: 'Intensità Filtrata',
  consistency: 'Consistenza',
  pi: 'P.I',
  close_alert: 'Chiudi Allarme',
  number_of_samples: 'Numero di Campioni',
  load: 'Carica',
  clean_sample: 'Campione Pulito',
  sample: 'Campione',
  device_type: 'Tipo di Dispositivo: {deviceType}',
  address_param: 'Indirizzo: {address}',
  reset_password: 'REIMPOSTA PASSWORD',
  rf_sync_problem_: 'Problema di Sincronizzazione RF, ',
  rsrp_dbm: 'RSRP [dbm]',
  radio_: 'Radio ',
  radio_and_audio_window_sec: 'Finestra Radio e Audio Sec',
  radio_dt_dt_quality_quality: 'Radio [dt: {dt}, qualità: {quality}]',
  ready: 'Pronto',
  record_duration: 'Durata Registrazione',
  recording_duration_sec: 'Durata Registrazione [Sec]',
  recording_durationsec: 'Durata RegistrazioneSec',
  recordingtime_1: 'Tempo di Registrazione 1',
  recordingtime_2: 'Tempo di Registrazione 2',
  recordingtime_3: 'Tempo di Registrazione 3',
  registered_microsoft_partner: 'Partner Microsoft Registrato',
  relative_sensor: 'Sensore Relativo',
  reload_data: 'Ricarica Dati',
  remember_me: 'Ricordami',
  remove_sensor_sensorid: 'Rimuovi Sensore {sensorID}',
  remove: 'Rimuovi',
  repair_date_: 'Data di Riparazione',
  report_enabled: 'Report Abilitato',
  reports_settings: 'Impostazioni Report',
  reports: 'Report',
  resets: 'Ripristini',
  sms: 'SMS',
  sop_id_: 'ID SOP: ',
  sop_status: 'Stato SOP',
  sop_type: 'Tipo di SOP',
  sops_filters: 'Filtri SOPs',
  sample_correlation_contrast: 'Contrasto Correlazione Campione',
  sample_correlation: 'Correlazione Campione',
  sample_id_: 'ID Campione: ',
  sample_noise: 'Rumore Campione',
  sample_size: 'Dimensione Campione',
  sample_spectrogram: 'Spettrogramma Campione',
  sample_time_: 'Tempo Campione: ',
  sampled_intensity: 'Intensità Campionata',
  samples2: 'Campioni2',
  sampling_frequency: 'Frequenza di Campionamento',
  save_this_couple_assessment_report: 'Salva questo rapporto di valutazione coppia',
  save_this_work_order: 'Salva questo ordine di lavoro',
  second_sensor: 'Secondo Sensore',
  select_projects: 'Seleziona Progetti...',
  select_report: 'Seleziona Report...',
  select_project: 'Seleziona Progetto',
  select_: 'Seleziona...',
  selected_selectedindex_total_total: 'Selezionati: {selectedIndex}. Totale: {total}',
  send_to_printer: 'Invia alla stampante',
  sensitivity_level: 'Livello di Sensibilità',
  sensor_id_: 'ID Sensore: ',
  sensor_id_id: 'ID Sensore: {id}',
  sensor_type: 'Tipo di Sensore',
  sensor_comment_: 'Commento Sensore',
  sensor_id_default: 'ID Sensore (predefinito)',
  sensor: 'Sensore',
  sensors_report: 'Report Sensori',
  sensors_filters: 'Filtri Sensori',
  sensors_options: 'Opzioni Sensori',
  service_provider_: 'Fornitore di Servizi',
  settings: 'Impostazioni',
  shadow: 'Ombra',
  show_hide: 'Mostra / Nascondi',
  show_all: 'Mostra Tutto',
  show_iquarius_samples: 'Mostra Campioni iQuarius',
  sim_number: 'Numero SIM',
  sopid: 'SopID',
  source_alert: 'Allarme Sorgente',
  start_frequency: 'Frequenza Iniziale',
  start_frequency_hz: 'Frequenza Iniziale (Hz)',
  start_time: 'Ora di Inizio',
  state: 'Stato',
  status_default: 'Stato (predefinito)',
  percent: 'Percentuale',
  total_count: 'Conteggio Totale',
  under_count: 'Conteggio Inferiore',
  over_count: 'Conteggio Superiore',
  mixed_over_under_count: 'Conteggio Tipo Misto',
  g5_count: 'Conteggio G5',
  g4_count: 'Conteggio G4',
  mixed_g_count: 'Conteggio Misto G',
  project_id: 'ID Progetto',
  project_display_name: 'Nome Visualizzato Progetto',
  status: 'Stato: ',
  sudden_air_temp_change: 'Variazione Improvvisa Temperatura Aria',
  task_id: 'ID Attività',
  time_: 'Ora: ',
  timezone: 'Fuso Orario',
  total_length_unitslable: 'Lunghezza Totale [{unitsLable}]',
  type_: 'Tipo: ',
  type_type: 'Tipo: {type}',
  under: 'Inferiore',
  underground_acc_gps: 'GPS Acc Sottosuolo',
  underground_acc_rf: 'RF Acc Sottosuolo',
  underground_hyd_gps: 'GPS Hyd Sottosuolo',
  underground_hyd_rf: 'RF Hyd Sottosuolo',
  uninstall_device_and_remove_sensor: 'Disinstalla Dispositivo e Rimuovi Sensore',
  uninstall_device_and_install_it_on_the_new_sensor: 'Disinstalla Dispositivo e Installalo sul Nuovo Sensore',
  uninstall_comment: 'Commento Disinstallazione',
  uninstall: 'Disinstalla',
  uninstalled: 'Disinstallato',
  update_customer_details: 'Aggiorna Dettagli Cliente',
  update_pipes: 'Aggiorna Tubi',
  update_project_details: 'Aggiorna Dettagli Progetto',
  update_user_details: 'Aggiorna Dettagli Utente',
  use_feet_in_matlab_graphs: 'Usa Piedi nei Grafici Matlab',
  use_imperial_units_in_graphs: 'Usa Unità Imperiali nei Grafici',
  user_name_name: 'Utente: {name}',
  users: 'Utenti',
  w: 'W',
  water_loss_unith: 'dispersioni idriche ({unit}/h)',
  weve_sent_a_message_to_email__please_check_your_inbox: 'Abbiamo inviato un messaggio a {email} \n Si prega di controllare la tua casella di posta.',
  work_area_: 'Area di Lavoro',
  your_item_located_in_project: 'Il tuo {item} si trova nel progetto {project}.',
  display_item: 'Mostra {item}',
  hz: '[Hz]',
  unitshz: ' [{units}/Hz]',
  balagan_dist_ratio_thr: 'balagan_dist_ratio_thr',
  couple: 'Coppia',
  false: 'falso',
  first: 'primo',
  ft: 'piedi',
  inactive_couple_no_sensors_planning: 'coppia inattiva - nessun sensore - pianificazione',
  inactive_couple_no_sensors: 'coppia inattiva - nessun sensore',
  inactive_couple: 'coppia inattiva',
  irrig_dist: 'irrig_dist',
  last: 'ultimo',
  length_length_units: 'lunghezza: {length}[{units}]',
  no_correlations_warning: 'nessun avviso di correlazione',
  slope_slope_std_std_r2_r2: 'pendenza: {slope}, std: {std}, r2: {r2}',
  sum_included_thr: 'somma_inclusa_thr',
  update_from_aqs_mobile_details: 'aggiornamento da AQS Mobile. ({details})',
  name_spectrogram: '{name} Spettrogramma',
  low_rm_rm: 'RM basso: [{rm}]',
  noise_num_samples: 'Numero Campioni Rumore',
  noise_tx_time: 'Tempo di Trasmissione Rumore',
  average_last: 'Media Ultimo',
  none: 'Nessuno',
  not_found: 'Non Trovato',
  not_include_not_installed_sensors: "* Non includere sensori 'Non Installati'",
  notices_and_electronic_communications: 'Avvisi e Comunicazioni Elettroniche',
  official_language: 'Lingua Ufficiale',
  op_audio_table: '{op} tabella audio',
  or: ' O “',
  original_audio: 'Audio Originale',
  other: 'Altro',
  overground: 'Superficie',
  override_length: 'Sostituisci Lunghezza',
  p: 'P',
  payload: 'Payload',
  payment: 'Pagamento',
  pca: 'PCA',

  sample_images: 'Immagini Campione',
  user_sample_classify: 'classifica',
  invalid_email_address: 'Indirizzo email non valido',
  phone_number: 'Numero di Telefono',
  pictures: 'immagini',
  pipe: 'Tubo:',
  pipe_coating: 'Rivestimento Tubo',
  pipe_condition_assessment_details: 'Dettagli Valutazione Condizione Tubo',
  pipe_condition_details: 'Dettagli Condizione Tubo',
  pipe_diameter: 'Diametro Tubo',
  pipe_id: 'ID Tubo',
  pipe_install_year: 'Anno Installazione Tubo',
  pipe_length: 'Lunghezza Tubo',
  pipe_length2: 'Lunghezza tubo:',
  filter_by_customer: 'Filtra per Cliente',
  check_devices: 'Verifica Dispositivi',
  copy_device_id_from_excel: 'Copia ID Dispositivi da Excel',
  count_devices: '{count} Dispositivi',
  selected_devices: '{selected} / {count} Dispositivi',
  pipe_material: 'Materiale Tubo',
  pipe_section_details: 'Dettagli Sezione Tubo',
  pipe_thickness: 'Spessore Tubo',
  pipe_thickness_at_break: 'Spessore Tubo (alla rottura)',
  pipe_type: 'Tipo di Tubo',
  pipes_materials: 'Materiali tubi:',
  please_uninstall_device: 'Si prega di Disinstallare il Dispositivo',
  point_1: 'Punto 1',
  point_2: 'Punto 2',
  poly_ethelen: 'Polietilene',
  position: 'Posizione:',
  preparing_to_download_if_download_does_not_commence_please_allow_popups_for_this_site: 'Preparazione al download. Se il download non inizia, consenti i popup per questo sito.',
  previous_break_operating_press: 'Pressione Operativa Rottura Precedente',
  prior_2_weeks_weather: 'Meteo delle 2 Settimane Precedenti',
  priority: 'Priorità',
  intensity_opr: 'Intensità Operativa',
  probability_opr: 'Probabilità Operativa',
  work_area: 'Area di Lavoro',
  repair_code: 'Codice Riparazione',
  contractor_id: 'ID Appaltatore',
  detected_at: 'Rilevato A',
  priority_default: 'Priorità (predefinita)',
  privacy_statement: 'Dichiarazione sulla Privacy',
  probability: 'Probabilità',
  processing_of_personal_data: 'Elaborazione dei Dati Personali',
  products: 'Prodotti',
  project: 'Progetto',
  project2: 'Progetto:',
  proximity_to_other_utilities: 'Prossimità ad Altre Infrastrutture',
  prs_calib_multiply: 'Moltiplicazione Calibrazione PRS',
  prs_calib_shift: 'Spostamento Calibrazione PRS',
  pvc: 'PVC',
  quality: 'Qualità:',
  radio: 'Radio',
  radio_frequency: 'Frequenza Radio',
  ref_time: 'Tempo di Riferimento',
  registration_payment: 'Registrazione; Pagamento',
  remove_filters_to_view_device: 'Rimuovi Filtri per Visualizzare il Dispositivo',
  item_is_hidden_by_filters: '{item} è nascosto dai filtri',
  
remove_sensor: 'Rimuovi Sensore',
  repair_date: 'Data Riparazione',
  repair_details: 'Dettagli Riparazione',
  add_new_alert: 'Aggiungi Nuovo Allarme',
  add_new_mobile_alert: 'Aggiungi Nuovo Allarme Mobile',
  repair_type: 'Tipo di Riparazione',
  detection_date: 'Data di Rilevamento',
  report: 'Report',
  reported_by: 'Segnalato Da',
  reset: 'Ripristina',
  resistivity: 'Resistività',
  restrictions_no_misuse_of_services: 'Restrizioni - Nessun Uso Improprio dei Servizi',
  result: 'Risultato',
  s: 'S',
  sample_id: 'ID Campione:',
  sample_time: 'Tempo Campione:',
  sample_time_1: 'Tempo Campione 1',
  sample_time_2: 'Tempo Campione 2',
  sample_time_3: 'Tempo Campione 3',
  samples: 'Campioni',
  sap_number: 'Numero SAP:',
  save: 'Salva',
  save_and_apply: 'Salva e Applica',
  save_as_excel: 'Salva come Excel',
  save_changes: 'Salva Modifiche',
  scheduler: 'Scheduler',
  search: 'Cerca',
  search_box: 'Casella di Ricerca',
  sec: 'sec',
  select: 'Seleziona...',
  select2: 'Seleziona',
  select_draw_mode_and_draw_area_of_interest: 'Seleziona modalità disegno e disegna Area di Interesse',
  select_sensor: 'Seleziona Sensore',
  selected_param: 'Selezionato: {selectedItems}',
  selected_sensor_id: 'Sensore Selezionato {id}',
  sensor_id_param2: 'Sensore: {id}',
  send: 'Invia',
  sensor1_address: 'Indirizzo Sensore1:',
  bands: 'Bande',
  days: 'Giorni',
  avg_max_leak_val_ndx: 'Media Massima Perdita [ndx: {maxProbIndex}, perdita ndx: {maxProbLeakIndex}, valore: {maxProbVal}]',
  sensor2_address: 'Indirizzo Sensore2:',
  sensor_activity_threshold: 'Soglia Attività Sensore',
  sensor_comment: 'Commento Sensore',
  wake_up_before_radio: 'Sveglia Prima della Radio',
  random_delay: 'Ritardo Casuale',
  sampling_freq: 'Frequenza Campionamento',
  sensor_id: 'ID Sensore',
  sensor_id2: 'ID Sensore:',
  sensor_id_param: 'ID Sensore: {id}',
  sensor_install: 'Installazione Sensore',
  sensor_is_not_found_check_filters_and_try_again: 'Sensore non trovato. Controlla i filtri e riprova',
  sensors: 'Sensori',
  sensors_nc_table: 'tabella sensori nc',
  service: 'Servizio',
  service_provider: 'Fornitore di Servizi',
  service_provider2: 'Fornitore di Servizi',
  service_subscription: 'Abbonamento al Servizio',
  session_max_value: 'Valore Massimo Sessione',
  session_min_value: 'Valore Minimo Sessione',
  session_num_events: 'Numero Eventi Sessione',
  set_actual_leak_position: 'Imposta Posizione Reale della Perdita',
  set_actual_leak_position_on_map: 'Imposta posizione reale della perdita sulla Mappa:',
  set_apn: 'Imposta APN',
  set_leak_valve_monitor: 'Imposta Monitor Valvola Perdita',
  event_magnitude_constant: 'Magnitudo Evento',
  magnitude_threshold: 'Soglia Magnitudo',
  leak_monitor_interval_sec: 'Intervallo Monitor Sec',
  leak_monitor_alert_suspend_interval_sec: 'Intervallo Sospensione Allarme Sec',
  sample_frequency_hz: 'Freq. Campionamento HZ',
  leak_monitor_long_sample_sec: 'Durata Campione',
  sample_duration: 'Durata Campione',
  notch_filter: 'Filtro Notch',
  compress_enabled: 'Compressione Abilitata',
  channel_10k: 'Canale 10k',
  
 radio_band_mask_1: 'Maschera Banda Radio 1',
  radio_band_mask_2: 'Maschera Banda Radio 2',
  radio_band_mask_3: 'Maschera Banda Radio 3',
  radio_band_mask_4: 'Maschera Banda Radio 4',
  audio_duration: 'Durata Audio',
  mode: 'Modalità',
  cat_m_preferred: 'CAT-M Preferito',
  nb_iot_preferred: 'NB-IOT Preferito',
  cat_m_only: 'Solo CAT-M',
  zone: 'Zona',
  threshold_3: 'Soglia 3',
  threshold_2: 'Soglia 2',
  threshold_1: 'Soglia 1',
  auto_gain: 'Guadagno Automatico',
  gain_1: 'Guadagno 1',
  gain_2: 'Guadagno 2',
  gain_3: 'Guadagno 3',
  gain_4: 'Guadagno 4',
  nb_iot_only: 'Solo NB-IOT',
  channel_num: 'Canale {num}',
  radio_duration: 'Durata Radio',
  audio_fs: 'FS Audio',
  sampling_window: 'Finestra Campionamento',
  audio_num_bands: 'Numero Bande Audio',
  audio_num_coff: 'Numero Coefficenti Audio',
  audio_band_mask_1: 'Maschera Banda Audio 1',
  audio_band_mask_2: 'Maschera Banda Audio 2',
  audio_band_mask_3: 'Maschera Banda Audio 3',
  audio_band_mask_4: 'Maschera Banda Audio 4',
  radio_num_bands: 'Numero Bande Radio',
  radio_num_coff: 'Numero Coefficienti Radio',
  audio_enable: 'Audio Abilitato',
  conf: 'Conf',
  fm2_conf: 'Conf fm2',
  fm1: 'fm1',
  fm2: 'fm2',
  corr_sample_freq: 'Frequenza Campioni Correlati',
  audio_report_enabled: 'Report Audio Abilitato',
  corr_radio_duration: 'Durata Radio Correlati',
  corr_audio_duration: 'Durata Audio Correlati',
  noise_audio_duration: 'Durata Audio Rumore',
  corr_audio_fs: 'FS Audio Correlati',
  wakeup_befor: 'Sveglia Prima',
  random_backoff_connect: 'Ritardo Casuale Connessione',
  dfu_fw: 'dfu_fw',
  dfu_type: 'Tipo dfu',
  dfu_block: 'Blocco dfu',
  modem_fw: 'Firmware Modem',
  snr1: 'snr1',
  snr2: 'snr2',
  last_updated: 'Ultimo Aggiornamento',
  tlv_server_time: 'Orario Server TLV',
  cellular_time: 'Orario Cellulare',
  apn: 'APN',
  domain: 'Dominio',
  modem_version: 'Versione Modem',
  search_users: 'Cerca Utenti',
  search_alert: 'Cerca Allarme',
  search_sensor: 'Cerca Sensore',
  search_alert_id: 'Cerca ID Allarme',
  model: 'Modello',
  iemi: 'IMEI',
  iccid: 'ICCID',
  search_installation_point: 'Cerca Punto di Installazione',
  search_interference: 'Cerca Interferenza',
  interference_id: 'ID Interferenza',
  set_default: 'Imposta Predefinito',
  prev: 'Prec',
  next: 'Succ',
  required: 'Richiesto',
  search_couple: 'Cerca Coppia',
  continue: 'Continua',
  you_are_about_to_take_a_significant_action_on_num_devices: 'STAI PER EFFETTUARE UN’AZIONE SIGNIFICATIVA SU {num} DISPOSITIVI:',
  
  confirm_updating_devices_apn_to: 'Conferma aggiornamento APN dispositivi a "{apn}"',
  search_task: 'Cerca Compito',
  lte_mode: 'Modalità LTE',
  confirm_updating_lte_mode_to: 'Conferma aggiornamento modalità LTE a "{mode}"',
  search_user: 'Cerca Utente',
  search_project: 'Cerca Progetto',
  search_customer: 'Cerca Cliente',
  filter_by: 'Filtra per',
  filter_by_project: 'Filtra per Progetto',
  filter_by_customer: 'Filtra per Cliente',
  set_capability: 'Imposta Capacità',
  set_fm_scan: 'Imposta Scansione FM',
  set_lte_configuration: 'Imposta Configurazione LTE',
  set_lte_mode: 'Imposta Modalità LTE',
  set_secondary_server: 'Imposta Server Secondario',
  low_frequency: 'Frequenza Bassa',
  high_frequency: 'Frequenza Alta',
  medium_frequency: 'Frequenza Media',
  secondary: 'secondario',
  boundaries: 'Confini',
  server: 'server',
  set_server: 'Imposta Server',
  set_time_source: 'Imposta Fonte Ora',
  set_tpcip_config: 'Imposta Configurazione TPC-IP',
  severity: 'Gravità:',
  show_av: 'Mostra AV',
  site_access_password_security: 'Accesso Sito &amp; Password; Sicurezza',
  slope_std_r2: 'pendenza: {slope}, std: {std}, r2: {r2}',
  snow_depth: 'Profondità Neve',
  sop_id: 'ID SOP:',
  sops: 'SOPs',
  sort_by: 'Ordina per',
  source: 'Fonte',
  status: 'Stato',
  stolen: 'Rubato',
  street_surface: 'Superficie Stradale',
  submit: 'Invia',
  subscriptions_and_licenses_restrictions: 'Abbonamenti e Licenze; Restrizioni',
  success: 'Successo',
  sudden_water_temp_change: 'Variazione Improvvisa Temperatura Acqua',
  supervisor_user: 'Utente Supervisore',
  support_services: 'Servizi di Supporto',
  switch_to_projectName: 'Passa a {projectName}',
  switch_to_project: 'Passa a {project}',
  switch: 'Cambia',
  sync: 'Sincronizza',
  system: 'Sistema',
  tabs_container: 'contenitore tab',
  task_name: 'Nome Compito',
  alert_name: 'Nome Allarme',
  temp_avg: 'Temp Media',
  temp_fail: 'Temp Fallita',
  temp_rise: 'Aumento Temp',
  term_and_termination: 'Termine e Risoluzione',
  terms: 'TERMINI',
  text_alignment: 'allineamento testo',
  this_is_a_demo_404_page: 'Questa è una pagina demo 404!',
  this_website_uses_cookies_to_enhance_the_user_expe: 'Questo sito utilizza i cookie per migliorare l’esperienza utente.',
  time: 'Tempo',
  time_limited_service: '"Servizio a Tempo Limitato"',
  time_limited_service_subscription: 'Abbonamento Servizio a Tempo Limitato',
  time_zone: 'Fuso Orario',
  to: 'A',
  to2: 'A:',
  to_businesses_and_professionals: ') a aziende e professionisti.',
  total: 'Totale',
  total2: 'Totale:',
  traffic: 'Traffico',
  transient: 'Transitorio',
  trend: 'Tendenza',
  type: 'Tipo',
  u: 'U',
  under_over: 'Inferiore / Superiore',
  underground: 'Sotterraneo',
  uninstall_device: 'Disinstalla Dispositivo',
  update: 'Aggiorna',
  update_from_aws_mobile_details: 'Aggiorna da AQS Mobile. ({details})',
  update_modem_version: 'Aggiorna Versione Modem',
  update_status_to: 'Stato Aggiornato a',
  update_version: 'Aggiorna Versione',
  update_version_ultra: 'Aggiorna Versione Ultra',
  updated: 'Aggiornato',
  upload_files: 'Carica File',
  upload_screenshot: 'Carica Screenshot',
  us_government_restricted_rights: 'Diritti Riservati del Governo degli Stati Uniti',
  user: 'Utente',
  delete_user: 'Elimina Utente',
  utc_time: 'Ora UTC',
  v: 'V',
  v_x: 'V: {totalSampledDays}, X: {totalUnSampledDays},',
  v_x2: 'V: {totalSampledDaysWithoutEmpty}, X: {totalUnSampledDaysWithoutEmpty},',
  value: 'Valore',
  valve: 'Valvola',
  valve_monitoring: 'Monitoraggio Valvola',
  velocity: 'Velocità',
  version: 'Versione',
  wall: 'Parete',
  g3e_fid: 'G3E FID',
  water_loss_unit_h: 'dispersioni idriche ({unit}/h)',
  water_type: 'Tipo di Acqua',
  pin_location: 'Posizione Pin',
  weather_conditions: 'Condizioni Meteo',
  week: 'Settimana',
  wood: 'Legno',
  work_area: 'Area di Lavoro',
  wover: 'wover',
  wt: 'wt',
  x: 'X',
  x_y: 'X , Y:',
  y: 'Y',
  you: 'TU',
  ver: 'Ver'
};
// // Get the keys and sort them
// const sortedKeys = Object.keys(en).sort();

// // Create a new object and assign the sorted keys with their respective values
// let sortedObject = {};
// for (let key of sortedKeys) {
//   sortedObject[key] = en[key];
// }

// console.log(sortedObject);
