import { Amplify , Auth} from 'aws-amplify';

Amplify.configure({
  Auth: {
    identityPoolId: 'ap-southeast-1:722f3f58-a6a1-4db9-8571-d2d4dbd7afc4',
    region: 'ap-southeast-1',
    userPoolId: 'ap-southeast-1_8fDT7yHvJ',
    userPoolWebClientId: '7ic9079jm3ah78po0842k5vvn6',
  }
  // Auth: {
  //   identityPoolId: 'ap-southeast-1:3215fbf1-163e-449f-b2bb-558fbe7c3582',
  //   region: 'ap-southeast-1',
  //   userPoolId: 'ap-southeast-1_NVkaIGG1T',
  //   userPoolWebClientId: '2erps99ir2ful9rhe1jnu2d71i',
  //   mandatorySignIn: false,
  // }
  // Auth: {
  //   identityPoolId: 'eu-west-1:95856554-1811-44eb-9be0-5ecd0a1f4770',
  //   region: 'eu-west-1',
  //   userPoolId: 'eu-west-1_9bLqXIVJh',
  //   userPoolWebClientId: '413p4l0ad8vdn8uehltjmtstsn',
  //   mandatorySignIn: false,
  // }
});

let onAuthStateChangeListener = null;

export function getCurrentUser() {
  return Amplify.Auth.user;
}

export function setListener(listener) {
  onAuthStateChangeListener = listener;
  const currentUser = getCurrentUser();
  if (currentUser == null) {
    onAuthStateChangeListener.onAuthStateChanged(null);
    onAuthStateChangeListener.logout();
  } else {
    currentUser.getSession((e, cognitoUserSession) => {
      if (e) {
        // console.log(e);
        onAuthStateChangeListener.onAuthStateChanged(null);
      } else if (cognitoUserSession == null) {
        onAuthStateChangeListener.onAuthStateChanged(null);
      } else {
        cognitoUserSession.getIdToken = () => new Promise((resolve) => {
          resolve(cognitoUserSession.accessToken.jwtToken);
        });
        onAuthStateChangeListener.onAuthStateChanged(cognitoUserSession);
        if (cognitoUserSession) {
          onAuthStateChangeListener.loggingIn(cognitoUserSession);
        }
      }
    });
  }
}

// const signInWithEmailAndPassword = async (email, password) => {
//   try {
//     const user = await Auth.signIn(email, password);
//     if (user.challengeName === 'NEW_PASSWORD_REQUIRED' || user.challengeName === 'SMS_MFA') {
//       return user;
//     }
//     return await getSessionForSignedInUser(user);
//   } catch (error) {
//     throw error;
//   }
//
// }

export function signInWithEmailAndPassword(email, password) {
  return new Promise((resolve, reject) => {
    Auth.signIn(email, password)
      .then((resUser) => {
        if (resUser.challengeName === 'NEW_PASSWORD_REQUIRED' ||
          resUser.challengeName === 'SMS_MFA'
        ) {
          resolve(resUser);
          return;
        }

        getSessionForSignedInUser(resUser)
          .then((session) => {
            resolve(session);
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export async function verifySmsMfaCode(user, code) {
  const result = await Auth.confirmSignIn(user, code);
  return getSessionForSignedInUser(result);
}

export function loginSetNewPassword(email, password, newPassword) {
  return new Promise((resolve, reject) => {
    Auth.signIn(email, password)
      .then((user) => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          Auth.completeNewPassword(user, newPassword)
            .then((resUser) => {
              getSessionForSignedInUser(resUser)
                .then((session) => {
                  resolve(session);
                })
                .catch((error) => {
                  reject(error);
                });
            }).catch(e => {
              reject(e);
            });
        } else {
          // other situations
          reject(new Error('Other situation'));
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function signOut() {
  return new Promise((resolve, reject) => {
    const user = getCurrentUser();
    if (!user) {
      reject(new Error('user not found'));
    } else {
      user.signOut();
      resolve(true);
    }
  });
}

export function sendPasswordResetEmail(email) {
  return Auth.forgotPassword(email);
}

export function setNewPassword(email, code, password) {
  return Auth.forgotPasswordSubmit(email, code, password);
}

function getSessionForSignedInUser(resUser) {
  return new Promise((resolve, reject) => {
    resUser.getSession((err, session) => {
      if (err) {
        reject(err);
      }
      else {
        if (onAuthStateChangeListener) {
          resUser.getIdToken = () => new Promise((resolve, reject) => {
            resUser.getSession((getSessionErr, getSessionResult) => {
              if (getSessionErr) {
                reject(getSessionErr);
              } else {
                const token = getSessionResult.accessToken.jwtToken;
                resolve(token);
              }
            });
          });
          onAuthStateChangeListener.onAuthStateChanged(resUser);
          onAuthStateChangeListener.loggingIn(resUser);
        }

        resolve(session);
      }
    });
  });
}
