import * as types from '../constants/ActionTypes';

// export function details(state = {}, action) {
//     switch (action.type) {
//         case types.REQUEST_LEAK_DETAILS:
//             return Object.assign({}, state, {
//                 [action.leak]: { isFetching: true, data: false }
//             });
//         case types.RECEIVE_LEAK_DETAILS:
//             return Object.assign({}, state, {
//                 [action.leak]: { data: action.details.data[0], isFetching: false }
//             });
//         default:
//             return state;
//     }
// }

export function history(state = {}, action) {
    switch (action.type) {
        case types.REQUEST_LEAK_HISTORY:
            return Object.assign({}, state, {
                [action.leak]: { isFetching: true, data: [] }
            });
        case types.RECEIVE_LEAK_HISTORY:
            return Object.assign({}, state, {
                [action.leak]: { data: action.history.data, isFetching: false }
            });
        default:
            return state;
    }
}
