import * as types from '../constants/ActionTypes';

const initialState = {
    isFetching: false,
    options: {}
}

export function optionList(state = initialState, action) {
    switch (action.type) {
        case types.USER_LOGGED_OUT:
            return initialState;
        case types.REQUEST_OPTIONS:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case types.RECEIVE_OPTIONS:
            return Object.assign({}, state, {
                isFetching: false,
                options: action.options,
                language: action.language,
                hasData: true
            });
        case types.ADD_OPTION_RECEIVE:
            return Object.assign({}, state, {
                options: Object.assign({}, state.options, action.options)
            });
        default:
            return state;
    }
}
