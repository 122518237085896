import PropTypes from 'prop-types';
import React from 'react';

import { Field, reduxForm } from 'redux-form';
import CManageDistributorProjects from '../../containers/CModals/CManageDistributorProjects';
import DeleteModal from '../CouplesTabs/DetailsTab/DeleteModal';
import Input from '../Input/Input';
import PhoneInputComp from '../Input/PhoneInputComp';
import Select from '../Select/Select';

class UserDetailsTab extends React.Component {

  constructor(props) {
    super(props);
  }

  required = (value) => {
    const error = (value) ? undefined : this.context.t('required');
    return error;
  }

  email = (value) => {
    const error = value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
      this.context.t('invalid_email_address') :
      undefined;
    return (error);
  }

  validatePhone = (value) => {
    if (!value) return 'Phone number is required';

    const formattedValue = value.startsWith('+') ? value : `+${value}`;

    const phoneRegex = /^\+?[1-9]\d{1,14}$/;
    return phoneRegex.test(formattedValue) ? undefined : 'Invalid phone number format';
  };

  // New password validation function
  validatePassword = (value) => {
    if (!value) return this.context.t('required');
    // At least 12 characters, one uppercase letter, one digit, and one symbol
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*\W).{12,}$/;
    return passwordRegex.test(value) ? undefined : this.context.t('invalid_password_format');
  };

  render() {

    const { user, bIsSelectedIsDistributor, customerOptions, authOptions, newUser, pristine, submitting, handleSubmit } = this.props;
    const buttonTitle = (newUser) ? this.context.t('create_new_user') : this.context.t('update_user_details');

    return (
      <div>
        <form className="form-horizontal sticky-actions" onSubmit={handleSubmit} autoComplete="off">

          <div className="inline-group">
            <label className="col-xs-4 control-label">{this.context.t('user_name')}:</label>
            <div className="col-xs-8 control-input">
              <Field name="UserName" component={Input} type="text" className="form-control"
                     disabled={!newUser} validate={this.required} />
            </div>
          </div>
          <div className="inline-group">
            <label className="col-xs-4 control-label">{this.context.t('email')}:</label>
            <div className="col-xs-8 control-input">
              <Field name="Email" component={Input} type="text" className="form-control"
                     disabled={!newUser} validate={[this.required, this.email]} />
            </div>
          </div>

          <div className="inline-group">
            <label className="col-xs-4 control-label">{this.context.t('first_name')}:</label>
            <div className="col-xs-8 control-input">
              <Field name="FirstName" component={Input} type="text" className="form-control"
                     validate={this.required} />
            </div>
          </div>
          <div className="inline-group">
            <label className="col-xs-4 control-label">{this.context.t('last_name')}:</label>
            <div className="col-xs-8 control-input">
              <Field name="LastName" component={Input} type="text" className="form-control"
                     validate={this.required} />
            </div>
          </div>
          <div className="inline-group">
            <label className="col-xs-4 control-label">{this.context.t('phone_number')}:</label>
            <div className="col-xs-8 control-input">
              <Field
                name="PhoneNumber"
                component={PhoneInputComp}
                className="tel-input"
                validate={JSON.parse(process.env.REACT_APP_ADD_PHONE_PLUS) && this.validatePhone}
                normalize={(value) => value && !value.startsWith('+') ? `+${value}` : value}
              />
            </div>
          </div>
          <div className="inline-group">
            <label className="col-xs-4 control-label">{this.context.t('customer')}:</label>
            <div className="col-xs-8 control-input">
              <Field
                validate={this.required}
                name="CustomerID"
                component={(props) => (
                  <Select {...props}
                          placeholderTitle={this.context.t('select')}
                          clearable={false}
                          backspaceRemoves={false}
                          multi={false}
                          options={customerOptions}
                  />
                )}
              />
            </div>
          </div>

          {process.env.REACT_APP_AUTH_PROVIDER !== 'cognito' && newUser &&
            <div className="form-group">
              <label className="col-xs-2 control-label">{this.context.t('password')}:</label>
              <div className="col-xs-10 control-input">
                <Field
                  name="Password"
                  component={Input}
                  type="password"
                  className="form-control"
                  validate={[this.required, this.validatePassword]}
                />
                {/* Display instructions for a strong password */}
                <small style={{ display: 'block', marginTop: '5px' }}>
                  {this.context.t('password_requirements_instructions')}
                </small>
              </div>
            </div>
          }

          <div className="form-group">
            <label className="col-xs-2 control-label">{this.context.t('authorization')}:</label>
            <div className="col-xs-10 control-input">
              <Field
                name="allowedAuth"
                component={(props) => (
                  <Select {...props}
                          placeholderTitle={this.context.t('select')}
                          clearable={false}
                          backspaceRemoves={false}
                          closeMenuOnSelect={false}
                          options={authOptions}
                          multi
                  />
                )}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="col-xs-2 control-label">{this.context.t('mfa_enabled')}:</label>
            <div className="col-xs-10 control-input">
              <Field
                name="mfa"
                component={Input}
                className="checkbox-control"
                type="checkbox"
                disabled={!user.editMode}
              />
            </div>
          </div>

          <div className="form-actions">
            {(user.isAQS && bIsSelectedIsDistributor) &&
              <a
                href='javascript:void(0);'
                className="btn btn-info"
                disabled={!user.isAQS}
                onClick={this.props.openManageDistributorModal}
                type="submit">{this.context.t('manage_distributor')}</a>
            }

            {
              user.isAQS && !newUser &&
              <DeleteModal
                id={user.FirstName}
                name={user.FirstName}
                type={'user'}
                disabled={!user.isAQS}
                onDelete={this.props.delete} />
            }
            <button
              className="btn btn-success btn-submit"
              disabled={pristine || submitting}
              type="submit">{buttonTitle}</button>
          </div>
        </form>

        <CManageDistributorProjects />

      </div>
    );
  }
}

UserDetailsTab.contextTypes = {
  t: PropTypes.func.isRequired
};

// Decorate the form component
const userDetailsTab = reduxForm({
  form: 'userDetailsForm',
  enableReinitialize: true,
})(UserDetailsTab);

export default userDetailsTab;
