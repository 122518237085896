import React from 'react';
import PropTypes from 'prop-types';

import I18n from "redux-i18n";
import { translations } from '../translations/tr';
import { useSelector } from 'react-redux';

import * as settings from '../constants/SettingsKeys';

export default function App(props) {

  const initialLanguage = localStorage.getItem(settings.LANGUAGE) || 'en';
  const [language, setLanguageState] = React.useState(initialLanguage);

  const currentLang = useSelector((state) => state.i18nState.lang);

  // React.useEffect(() => {
  //   if (currentLang && currentLang !== language) {
  //     setLanguageState(currentLang);
  //     localStorage.setItem(settings.LANGUAGE, currentLang);
  //   }
  // }, [currentLang, language]);

  React.useEffect(() => {
    setLanguageState(initialLanguage);
  }, []);

  return (
    <I18n translations={translations} initialLang={language} key={language}>
      <div className="page-container">
        {props.children}
      </div>
      <input
        style={{ display: 'none' }}
        id="recaptcha"
        type="submit"
        value="Send Phone"
      />

    </I18n>
  );
}

App.propTypes = {
  children: PropTypes.element.isRequired
};
