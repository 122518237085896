export const fr = {
  Sensor_id: 'Capteur ID',
  X: 'X',
  a: 'A',
  ownership: 'Propriété',
  account: 'Compte',
  active: 'Actif',
  add: 'Insérer',
  alert_classification: 'Classification des Alertes',
  add_new_noise_alert: 'Ajouter une Nouvelle Alerte de Bruit',
  add_new_task: 'Ajouter une Nouvelle Tâche',
  add_sensor: 'Ajouter un Capteur',
  address: 'Adresse',
  adhoc_correlation: 'Corrélation ad hoc',
  advance_alerts: 'Alertes Avancées',
  advance_pipes: 'Tuyaux Avancés',
  advanced: 'Avancé',
  agc_config: 'Configuration AGC',
  agreement: 'Accord',
  alert: 'Alerte',
  alert_alertId: 'Alert {alertId}',
  alert_comment: 'Note d\’alerte',
  alert_id: 'Alert ID',
  a_valid_segment: 'Un Segment Valide',
  avg_last_correlation: 'Dernière correlation moyenne',
  avg_last_spectrogram: 'Dernier spectrogramme moyen',
  above_under: 'Au-dessus / Sous',
  above: 'Au-dessus',
  action: 'Action',
  address_: 'Adresse: ',
  addresses_address1_address2: 'Adresses: {adresse1}, {adresse2}',
  alert_date: 'Date dalerte: ',
  alert_id_filter: 'Alert ID Filter',
  alert_id_id: 'Alert ID {id}',
  alert_id_: 'Alert ID: ',
  alert_id_id_: 'Alert ID: {id}',
  id: 'ID',
  alert_status: 'Alert Status',
  alert_type: 'Alert Type',
  alert_alertid: 'Alert {alertId}',
  alerts_options: 'Options dalertes',
  alerts: 'Alertes',
  allow_false_positive: 'Autoriser faux positif',
  allow_prod2_run: 'Autoriser lexécution de Prod2',
  allowprod2run: 'AutoriserlexécutiondeProd2',
  analyzer: 'Analyseur',
  apply_changes: 'Appliquer les modifications',
  are_you_sure_you_want_to_remove_this_device:
    'Êtes-vous sûr de vouloir supprimer cet appareil?',
  are_you_sure_you_want_to_uninstall_the_device_and_remove_this_sensor:
    'Êtes-vous sûr de vouloir désintaller l\’appareil et supprimer ce capteur?',
  are_you_sure_you_want_to_uninstall_the_device:
    'Êtes-vous sûr de vouloir désintaller \’appareil?',
  are_you_sure_you_want_to_remove_this_sensor:
    'Êtes-vous sûr de vouloir supprimer ce capteur?',
  are_you_sure: 'Êtes-vous sûr?',
  this_action_will_rerun_override:
    'Cette action remplacera les échantillons précédents',
  confirmation_success: 'Voici un confirmation que votre action a réussi',
  confirmation_error: 'Votre action a échoué',
  assessment_id_id: 'ID d\’évaluation: {id}',
  assetid_asset: 'ID d\’Actif: {actif}',
  attenuation: 'Atténuation',

  recording_time_1: 'Heure du Premier Enregistrement',
  recording_time_2: 'Heure du Deuxième Enregistrement',
  recording_time_3: 'Heure du Troisième Enregistrement',
  authorization: 'Autorisation',
  auto: 'Automatique',
  autoclosedate: 'Date de Fermeture Automatique',
  azbest_cement: 'Ciment amiante',

  alert_id_param: 'ID d\’alerte : {id}',
  alert_state: 'État de l\’Alerte',
  alert_statuses: 'Statuts des Alertes',
  all_boundaries_selected: 'Toutes les Limites sélectionnées',
  analyze: 'analyser',
  application_services: 'Services d\’Application',
  archived: 'Archivé',
  are_you_sure_you_want_to_delete_this_type:
    'Êtes-vous sûr de vouloir supprimer ce {type}?',
  asc: 'ASC',
  assembly: 'Assemblage',
  blank_space: ' ',
  asset_id: 'ID d\’Actif',
  asset_id_param: 'ID d\’Actif : {asset}',
  exported_to_google_sheet_successfully:
    'Exporté vers Google Sheet avec succès',
  open_google_sheet: 'Ouvrir Google Sheet',
  export_to_excel: 'Exporter vers Excel',
  export_alerts_history: 'Exporter l\’historique des alertes',
  export_noise_alerts_history: 'Exporter l\’historique des alertes de bruit',
  sensors_maintenance: 'Maintenance des capteurs',
  sensors_activity_report: 'Rapport d\’activité des capteurs',
  sensors_technician_report: 'Rapport des capteurs - Technicien',
  sensors_admin_report: 'Rapport des capteurs - Administration',
  there_is_no_data_available: 'Aucune donnée disponible',
  no_data_available: 'Aucune donnée disponible',
  based_on_last_weeks_data: 'Basé sur les données de la semaine dernière',
  network_response_not_ok: 'La réponse réseau n\’est pas correcte',
  technician_report: 'Rapport technicien',
  report_type: 'Type de rapport',
  select_customer: 'Sélectionner le client',
  rerun_historical_data: 'Relancer les données historiques',
  select_time_frame: 'Sélectionner la période',
  time_source: 'Source de temps',
  valve_leak: 'Fuite de vanne',
  is_ultra: 'Ultra',
  rerun: 'Relancer',
  please_select: 'Veuillez sélectionner',
  export_shp_file: 'Exporter le fichier SHP',
  export_alerts_to_excel: 'Exporter les alertes vers Excel',
  export_all_transients_to_excel: 'Exporter tous les transitoires vers Excel',
  hello_username: 'Bonjour {userName}',
  about: 'À propos',
  help: 'Aide',
  logout: 'Déconnexion',
  licneces: 'Licences',
  local: 'Locale',
  gmt: 'GMT',
  last_correlation: 'Dernière corrélation',
  last_spectrogram: 'Dernier spectrogramme',
  radio_quality: 'Radio [dt : {dt}, qualité : {quality}]',
  please_enter_your_new_password_below: 'Veuillez entrer votre nouveau mot de passe ci-dessous',
  enter_new_password_again: 'Confirmez le nouveau mot de passe',
  meter: 'm',
  fit: 'pi',
  not_sensitive: 'Non sensible',
  is_emi: 'Est EMI',
  is_saturation: 'Est en saturation',
  is_delayed_wakeup: 'Réveil retardé',
  iq_100b_reports: 'Rapports IQ100B',
  is_white_noise: 'Est bruit blanc',
  is_overlapping: 'Est superposé',
  audio: 'Audio',
  device_installation_date: 'Date d\’installation de l\’appareil : {deviceInstallationDate}',
  device_generation: 'Génération de l\’appareil',
  audio_clean: 'Audio net',
  sync_method: 'Méthode de synchronisation',
  average: 'Moyenne',
  avg_max_correlation: 'Corrélation max moyenne',
  avg_max_spectrogram: 'Spectrogramme max moyen',

  bit_information: 'Information BIT',
  bit_type: 'Type de BIT',
  back: 'Retour',
  bad_status: 'Mauvais état',
  battery_voltage_post: 'Tension de batterie après',
  battery_voltage_pre: 'Tension de batterie avant',
  battery: 'Batterie',
  bounding: 'Délimitation',
  csq: 'CSQ',
  cannot_find_your_search: 'Recherche introuvable',
  cast_iron: 'Fonte',
  change_statename_of_leak_id_from_prevstate_to_nextstate:
    'Changer {stateName} de la fuite {id} de {prevState} à {nextState}',
  change_statename_of_alert_type_from_prevstate_to_nextstate:
    'Changer {stateName} de {alertType} {id} de {prevState} à {nextState}',
  circle: 'Cercle',
  city: 'Ville',
  click_to_set_current_map_position_as_project_center:
    'Cliquez pour définir la position actuelle de la carte comme centre du projet',
  comm_ratio: 'Ratio de communication',
  comment: 'Commentaire',
  comment_date: 'Date du commentaire',
  communication_problem: 'Problème de communication',
  compression_enabled: 'Compression activée',
  compression: 'Compression',

  b: 'B',
  back_to_home_view: 'Retour à l\’accueil',
  back_to_qalertsq: 'Retour aux alertes',
  backfill: 'Remblai',
  backfill_comment: 'Commentaire de remblai',
  bedding_comment: 'Commentaire de lit de pose',
  bedding_type: 'Type de lit de pose',
  brass: 'Laiton',

  config_record_duration: 'Configurer la durée d\’enregistrement',
  config_status: 'Statut de configuration',
  consumption_time_: 'Heure de consommation : ',
  consumtion: 'Consommation',
  consumtion_: 'Consommation : ',
  contact_email: 'Email de contact',
  contact_person: 'Personne de contact',
  contact_phone_1: 'Téléphone de contact 1',
  contact_phone_2: 'Téléphone de contact 2',
  correlation_audio: 'Audio de corrélation',
  correlation_radio: 'Radio de corrélation',
  couple_coverage: 'Couverture des couples',
  couple_deleted_successfuly: 'Couple supprimé avec succès',
  couple_id_: 'ID de couple : ',
  couple_id_coupleid_material_count_alerts:
    'ID de couple : {CoupleID},   Matériau : {material},   {Count} alertes',
  couple_id_id: 'ID de couple : {id}',
  couple_path: 'Chemin du couple :',
  couple_coupleid: 'Couple {coupleId}',
  couple_id_id_: 'Couple {id}',
  couples_days_back_for_only_updated: 'Jours en arrière pour les couples uniquement mis à jour',
  create_couple: 'Créer un couple',
  create_task: 'Créer une tâche',
  create_new_customer: 'Créer un nouveau client',
  create_new_project: 'Créer un nouveau projet',
  create_new_sop: 'Créer un nouveau SOP',
  create_new_user: 'Créer un nouvel utilisateur',
  create_new_area_of_interest: 'Créer une nouvelle zone d\’intérêt',
  creation_time: 'Heure de création',
  customer_id_: 'ID du client : ',
  customer_name: 'Nom du client',
  customer_customer: 'Client : {customer}',
  customer_name_: 'Client : {name}',
  customers: 'Clients',
  c: 'C',
  cancel: 'Annuler',
  yes: 'Oui',
  changed: 'Modifié',
  choose_location_for_new_installation_point:
    'Choisissez l\’emplacement pour le nouveau point d\’installation',
  claims: 'Réclamations',
  clarity: 'Clarté :',
  english: 'Anglais',
  hebrew: 'Hébreu',
  layer_manager: 'Gestionnaire de calques',
  satellite_view: 'Vue satellite',
  show_alerts: 'Afficher les alertes',
  minimal_noise_intensity: 'Intensité de bruit minimal',
  filtered_noise_intensity: 'Intensité de bruit filtré',
  show_mobile_samples: 'Afficher les échantillons mobiles',
  show_mobile_alerts: 'Afficher les alertes mobiles',
  show_sensors: 'Afficher les capteurs',
  show_installation_points: 'Afficher les points d\’installation',
  show_areas_of_interest: 'Afficher les zones d\’intérêt',
  pipes_by_material: 'Tuyaux par matériau',
  pipes_by_diameter: 'Tuyaux par diamètre',
  pipes_by_pressure: 'Tuyaux par pression',
  pipes_by_placement_year: 'Tuyaux par année de pose',
  couples_coverage: 'Couverture des couples',
  show_couples_path: 'Afficher le chemin des couples',
  project_boundaries: 'Limites du projet',
  show_water_meter_layer: 'Afficher le calque de compteur d\’eau',
  project_coverage: 'Couverture du projet',
  email: 'Email',
  password: 'Mot de passe',
  forgot_password: 'Mot de passe oublié',
  login: 'Connexion',
  manual_reset_at: 'Réinitialisation manuelle à {lastManualResetDate}',
  manual_reset_count: 'Nombre de réinitialisations manuelles {manualResetCount}',
  auto_reset_at: 'Réinitialisation automatique à {lastAutoResetDate}',
  auto_reset_count: 'Nombre de réinitialisations automatiques {autoResetCount}',
  sensors_activity: 'Activité des capteurs',
  select_month: 'Sélectionner le mois',
  boundary: 'Limite',
  include_archived_sensors: 'Inclure les capteurs archivés',
  all: 'Tous',
  sensor_1: 'Capteur 1',
  sensor_2: 'Capteur 2',
  device: 'Appareil',
  pressure: 'Pression',
  g5_status: 'Statut G5',
  classification: 'Classification',
  clear_selection: 'Effacer la sélection',
  click_on_map_to_set_coordinate: 'Cliquez sur la carte pour définir les coordonnées',
  complete_alert_details_and_save: 'Veuillez compléter les détails de l\’alerte et sauvegarder',
  click_to_download: 'Cliquez pour télécharger',
  close: 'Fermer',
  close_noise_alert: 'Fermer l\’alerte de bruit',
  close_valve_alert: 'Fermer l\’alerte de vanne',
  closure_date: 'Date de clôture :',
  code: 'Code',
  commands: 'Commandes',
  commented: 'Commenté : ',
  comments: 'Commentaires',
  communications_service: 'Service de communications”',
  compaction: 'Compactage',
  compliance_with_laws: 'Conformité aux lois',
  concrete: 'Béton',
  config: 'Configuration',
  config_duration: 'Durée de configuration',
  configuration: 'Configuration',
  configure: 'Configurer',
  consent_to: 'Consentement à',
  consent_to_use_of_data: 'Consentement à l\’utilisation des données',
  consumption: 'Consommation :',
  consumption_time: 'Heure de consommation :',
  contact_details: 'Détails de contact',
  content: 'Contenu',
  contrast: 'Contraste',
  control_buttons: 'Boutons de contrôle',
  coordinate: 'Coordonnées',
  copper: 'Cuivre',
  copyright_2021_aquarius_spectrumbrbr_all_rights_re:
    'Droits d\’auteur © 2021 Aquarius Spectrum Tous droits réservés. Aquarius Spectrum, Greenwork Yakum, Bâtiment A 1er étage, Yakum, 6097200 Israël Téléphone : +972-74-7136644 Fax : +972-4-6709014',
  corr_interval: 'Intervalle de corrélation',
  corr_num_samples: 'Nombre d\’échantillons de corrélation',
  correlation: 'Corrélation',
  corrosion_type: 'Type de corrosion',
  cost: 'Coût :',
  count: 'Nombre',
  couple_and_id: 'Couple {id} :',
  couple_assessment_report: 'Rapport d\’évaluation du couple',
  couple_id: 'ID du couple',
  couple_id2: 'Couple {coupleId} :',
  couple_length: 'Longueur du couple',
  couple_comment: 'Commentaire du couple',
  couples: 'Couples',
  couples_options: 'Options de couples',
  crack_diameter_inches: 'Diamètre de la fissure (pouces)',
  create: 'Créer',
  create_a_new_noise_alert: 'Créer une nouvelle alerte de bruit',
  create_a_new_valve_alert: 'Créer une nouvelle alerte de vanne',
  create_date: 'Date de création',
  create_new_installation_point: 'Créer un nouveau point d\’installation',
  created_new_alert: 'Nouvelle alerte créée',
  current_device_id: 'ID de l\’appareil actuel',
  current_status: 'Statut actuel',
  customer: 'Client',
  customer_account: 'Compte client',
  customer_customerParam: 'Client : {customer}',
  flow_param: 'Débit : {flow}',
  from_date_date: 'De la date : {date}',
  amplitude: 'Amplitude',
  file: 'Fichier',
  min_rms: 'RMS min',
  catm_flags: 'catm_flags',
  hw_v: 'HW V',
  classify: 'classifier',
  min: 'min',
  nc_3: 'nc_3',
  threshold: 'seuil',
  contact_name: 'Nom du contact',
  hi: 'salut',
  assambly: 'assemblage',
  g5_intensity: 'Intensité G5',
  comm_messages: 'Messages de communication',
  installation_point: 'Point d\’installation',
  bit_samples: 'Échantillons Bit',
  bit_messages: 'Messages Bit',
  bit: 'Bit',
  gain: 'Gain',
  download: 'Télécharger',
  seconds: 'Secondes',
  freq: 'Fréquence',
  noise: 'Bruit',
  low: 'Faible',
  radio_clean: 'Radio nettoyée',
  power: 'Puissance',
  radio_frequency_data: 'Données de fréquence radio',
  noise_intensity_min_band_power: 'Puissance de bande minimale d\’intensité de bruit',
  audio_data_size: 'Taille des données audio',
  request_time: 'Heure de la demande',
  response_time: 'Heure de la réponse',
  selected_fm_channel: 'Canal FM sélectionné',
  tp_avg: 'TP moyen',
  tp_rms: 'TP RMS',
  std_agc: 'STD AGC',
  std_agc_2: 'STD AGC 2',
  tp_rms_2: 'TP RMS 2',
  original: 'Original',
  audio_gain: 'Gain audio',
  imsi: 'IMSI',
  batt: 'Batt',
  acc_tech: 'Tech Acc',
  tech: 'Tech',
  nbiot_flags: 'nbiot_flags',
  nbiot_tx_tout: 'nbiot_tx_tout',
  nbiot_rx_tout: 'nbiot_rx_tout',
  nbiot_tx_power: 'nbiot_tx_power',
  nbiot_tx_gain: 'nbiot_tx_gain',
  nbiot_packet: 'nbiot_packet',
  catm_packet: 'catm_packet',
  catm_rx_tout: 'catm_rx_tout',
  catm_tx_tout: 'catm_tx_tout',

  rsrq_db: 'RSRQ [dB]',
  rsrp_db: 'RSRP [dB]',
  hw_rev: 'Rév. HW',
  nb: 'NB',
  unknown: 'Inconnu',
  cat_m: 'Cat-M',
  fw_rev: 'Rév. FW',
  fw_v: 'FW V.',
  rst: 'RST',
  orig_2: 'Orig 2',
  technology: 'Technologie',
  cell_id: 'ID Cellule',
  med: 'Moyen',
  high: 'Élevé',
  customer_id: 'ID du client :',
  customer_indemnification: 'Indemnisation du client',
  dmas_layer: 'Calque DMA',
  dates: 'Dates',
  days_correlation_incative: 'Jours de corrélation inactive',
  days_inactive: 'Jours inactifs',
  delete_failed: 'Échec de la suppression',
  device_id_: 'ID de l\’appareil : ',
  device_id_device: 'ID de l\’appareil : {device}',
  device_installation: 'Installation de l\’appareil',
  device_already_installed_in_project_project:
    'L\’appareil est déjà installé dans le projet {project}',
  diameter_placement_year: 'Diamètre / Année de pose',
  dist_m: 'Dist[m]',
  do_not_take_0_samples_in_average: 'Ne pas prendre 0 échantillon en moyenne',
  done: 'Terminé',
  download_audio: 'Télécharger l\’audio',
  download_log_file: 'Télécharger le fichier journal',
  draw_mode: 'Mode dessin',
  dual_signal: 'Double signal',
  d: 'D',
  dashboard: 'Tableau de bord',
  date: 'Date',
  day: 'Jour',
  days_detected: 'Jours détectés :',
  delete: 'Supprimer',
  delete_type_id: 'Supprimer {type} {id}',
  deletion_of_customer_data: 'Suppression des données du client',
  deletion_of_data: 'Suppression des données',
  desc: 'DESC',
  design_operating_press: 'Pression de fonctionnement de conception',
  details: 'Détails',
  detected: 'Détecté',
  device_configuration_messages_logs: 'Journaux des messages de configuration de l\’appareil',
  device_id: 'ID de l\’appareil',
  device_id_val: 'Appareil {id}',
  mobile_alert_id_val: 'Alerte Mobile {id}',
  frequency_hz: 'Fréquence : {freq} [Hz]',
  duration_sec: 'Durée : [sec]',
  access_point: 'Point d\’accès',
  fill_addresses: 'Renseigner les adresses',
  task_samples: 'Échantillons de tâche',
  samples_count: 'Nombre d\’échantillons',
  creation_date_default: 'Date de création (par défaut)',
  tasks: 'Tâches',
  tasks_options: 'Options de tâches',
  tasks_filters: 'Filtres de tâches',
  alerts_filters: 'Filtres d\’alertes',
  creation_date: 'Date de création',
  survey: 'Relevé',
  devices: 'Appareils',
  clean: 'Nettoyé',
  raw: 'Brut',
  low_intensity: 'Faible intensité',
  user_name: 'Nom d\’utilisateur',
  duration: 'Durée',
  intensity_raw: 'Intensité [brut]',
  intensity_db: 'Intensité : [dB]',
  intensity: 'Intensité',
  battery_level: 'Niveau de batterie',
  terms_and_conditions: 'Conditions générales',
  battery_post: 'Batterie après',
  fw_version: 'Version FW',
  hw_version: 'Version HW',
  rsrq: 'RSRQ',
  rsrp: 'RSRP',
  rssi: 'RSSI',
  snr: 'SNR',
  noise_gain: 'Gain de bruit',
  noise_min_rms: 'Bruit RMS min',
  normalize_noise: 'Normaliser le bruit',
  by_clicking_login_you_agree_to_our: 'En cliquant sur "Connexion", vous acceptez nos',
  fm_radio_channel: 'Canal radio FM',
  and_our: 'et nos',

  get_audio_config: 'Obtenir la configuration audio',
  get_time_zone: 'Obtenir le fuseau horaire',
  get_lte_mode: 'Obtenir le mode LTE',
  get_server: 'Obtenir le serveur',
  get_fm_channel: 'Obtenir le canal FM',
  start_fm_scan: 'Lancer le scan FM',
  get_apn: 'Obtenir l\’APN',
  get_valve_leak: 'Obtenir la fuite de vanne',
  get_agc_conf: 'Obtenir la configuration AGC',
  get_fallback_server: 'Obtenir le serveur de secours',
  get_capability: 'Obtenir la capacité',
  get_lte_configuration: 'Obtenir la configuration LTE',
  bit_request: 'Demande BIT',
  activate_toolkit: 'Activer la boîte à outils',
  you_are_about_to_take_a_significant_action:
    'Vous êtes sur le point de réaliser une action significative',
  sure_you_want_to_deactivate_sensors: 'Êtes-vous sûr de vouloir désactiver les capteurs ?',
  privecy_policy: 'Politique de confidentialité',
  confirm_updating_devices_server: 'Confirmer la mise à jour du serveur des appareils',
  confirm_updating_devices_as_version:
    'Confirmer la mise à jour des appareils en version "{version}"',
  get_scheduler: 'Obtenir le planificateur',
  discard: 'Annuler',
  edit: 'Modifier',
  deactivate: 'Désactiver',
  no_leak: 'Aucune fuite',
  suspected_fast_developing_leak: 'Fuite à développement rapide suspectée',
  suspected_leak: 'Fuite suspectée',
  uncertain: 'Incertain',
  undefined: 'Indéfini',
  hydrant: 'Borne d\’incendie',
  sample_intensity: 'Intensité de l\’échantillon',
  weighted_intensity: 'Intensité pondérée',
  low_rm: 'faible RM : [{rm}]',
  prob: 'Prob',
  show_in_5g: 'Afficher en 5G',
  draw_sensors_selection_area: 'Dessiner la zone de sélection des capteurs',
  sensors_selected: '{num} capteurs sélectionnés',
  last_sample: 'Dernier échantillon :',
  couple_length_units: 'Longueur du couple : {coupleLength}[{units}]',
  diameter_or_placement_year: 'Diamètre / Année de pose',
  disclaimer: 'Avertissement',
  distance: 'Distance :',
  distance_between_sensors: 'Distance entre les capteurs :',
  distance_calculation: 'Calcul de la distance :',
  distance_from_sensor1: 'Distance depuis le capteur 1 :',
  distances: 'Distances',
  diameter_inch: 'Diamètre en pouces',
  distribution_list: 'Liste de distribution :',
  download_failed_file_not_found: 'Échec du téléchargement - fichier introuvable',
  download_sensor_audio: 'Télécharger l\’audio du capteur',
  ductile_iron: 'Fonte ductile',
  end_user: 'UTILISATEUR FINAL',
  enter_an_mfa_code_to_complete_signin:
    'Entrez un code MFA pour terminer la connexion.',
  expand_row: 'développer la ligne',
  export2: 'Exporter',
  export_the_view_to_PDF: 'Exporter la vue en PDF',
  epsg: 'EPSG',
  edit_distributorname_projects: 'Modifier les projets {distributorName} :',

  end_frequency: 'Fréquence de fin',
  end_frequency_hz: 'Fréquence de fin (Hz)',
  end_frequncy: 'Fréquence de fin',
  end_record_time: 'Heure de fin d\’enregistrement',
  end_time: 'Heure de fin',
  estimated_address: 'Adresse estimée',
  event_cause: 'Cause de l\’événement',
  event_date: 'Date de l\’événement',
  event_information: 'Informations sur l\’événement',
  event_max_value: 'Valeur max de l\’événement',
  event_min_value: 'Valeur min de l\’événement',
  event_nature: 'Nature de l\’événement',
  exclude_sample_1_by_4_flag: 'Exclure l\’échantillon 1 par 4 indicateur',
  exclude_sample_1_by_4_number: 'Exclure l\’échantillon 1 par 4 numéro',
  exclude_sample_1_by_4_radius: 'Exclure l\’échantillon 1 par 4 rayon',

  export_all_projects_to_excel: 'Exporter tous les projets vers Excel',

  iq100b_reports: 'Rapports IQ100B',
  export_the_view_to_pdf: 'Exporter la vue en PDF',
  export: 'Exporter',
  ftpend_send_time: 'Heure de fin d\’envoi FTP',
  file_size: 'Taille du fichier',
  fading_alert: 'Alerte en atténuation',
  false_path: 'Faux chemin',
  fast_development: 'Développement rapide',
  file_name: 'Nom du fichier',
  filtered_avg: 'Moyenne filtrée',
  filtered_intensity_avg: 'Intensité moyenne filtrée',
  filtered_noise: 'Bruit filtré',
  filters_: 'Filtres :',
  firmware_version: 'Version du firmware',
  first_name: 'Prénom',
  fixed_leaks: 'Fuites réparées',
  flow_flow: 'Débit : {flow}',
  fmax: 'Fmax',
  fragmented_file: 'Fichier fragmenté',
  frequency_histogram: 'Histogramme de fréquence',
  failed: 'Échoué',
  fees: 'Frais',
  files: 'Fichiers',
  filter: 'Filtre',
  filters: 'Filtres',
  first_installation_date: 'Date de première installation',
  fm2_receiver: 'Récepteur FM2',
  fm_receiver: 'Récepteur FM',
  fmin: 'fmin',
  frequency: 'Fréquence',
  from: 'De :',
  from_to: 'De - À',
  frost_depth: 'Profondeur du gel',
  fs: 'fs',
  go: 'Aller',
  gps_ratio: 'Ratio GPS',
  generate: 'Générer',
  good_status: 'Bon état',
  fast_growing_alert: 'Alerte de croissance rapide',
  slow_growing_alert: 'Alerte de croissance lente',
  guidelines_: 'Directives :',
  hardware_version: 'Version du matériel',
  high_severity_more_than_4_leaks: 'Gravité élevée (plus de 4 fuites)',
  high_frq: 'Frq Elevée',
  mid_frq: 'Frq Moyenne',
  low_frq: 'Frq Basse',
  inactive: 'Inactif',
  index_band_to_send: 'Indice de bande à envoyer',
  index_: 'Indice : ',
  infrastructure: 'Infrastructure',
  install_date: 'Date d\’installation',
  installation_point_comment: 'Commentaire du point d\’installation',
  intensity_not_filtered: 'Intensité (non filtrée)',
  intensity_: 'Intensité : ',
  intensity_intensity: 'Intensité : {intensity}',
  area_of_interest_comment: 'Commentaire de la zone d\’intérêt',
  area_of_interest_id_id: 'Zone d\’intérêt {id}',
  area_of_interest_type: 'Type de zone d\’intérêt',
  area_of_interest: 'Zone d\’intérêt',
  areas_of_interest: 'Zones d\’intérêt',
  edit_area_of_interest: 'Modifier la zone d\’intérêt',
  is_csq_ok: 'CSQ OK',
  is_display_city_column: 'Afficher la colonne Ville',
  is_elecrolysis: 'Est Électrolyse',
  is_modem_closed_ok: 'Modem fermé OK',
  is_send_file_ok: 'Fichier envoyé OK',
  is_ok: 'Est OK',
  log: 'Journal',
  last_event_date: 'Date du dernier événement',
  last_name: 'Nom de famille',
  last_run_days: 'Dernière exécution (jours)',
  last_sample_: 'Dernier échantillon : ',
  last_uninstall_reason: 'Dernière raison de désinstallation',
  lasteventfrequency: 'Fréquence du dernier événement',
  leak_per_units: 'Fuite par {units}',
  leakgrowth: 'Croissance de la fuite',
  leakperkm: 'Fuite par km',
  leaks_infrastructure: 'Fuites / Infrastructure',
  leaks_by_diameter: 'Fuites par diamètre',
  leaks_by_materials: 'Fuites par matériaux',
  leaks_by_years: 'Fuites par années',
  leaks: 'Fuites',
  legend: 'Légende',
  linked_couples: 'Couples liés',
  list_name: 'Nom de la liste',
  log_information_device_deviceid: 'Informations du journal. Appareil : {deviceId}',
  low_severity_1_leak: 'Gravité faible (1 fuite)',
  low_severity_with_fast_development_alert:
    'Gravité faible avec alerte de développement rapide',
  mail: 'Courrier',
  main: 'Principal',
  manage_distributor: 'Gérer le distributeur',
  manual: 'Manuel',
  map_alerts: 'Alertes sur la carte',
  map_pipes_diameter_type: 'Carte des types de diamètre des tuyaux',
  material_diameter_placement_year: 'Matériau & Diamètre / Année de pose',
  material_placement_year: 'Matériau / Année de pose',
  material_name: 'Nom du matériau',
  medium_severity_2_4_leaks: 'Gravité moyenne (2 - 4 fuites)',
  meteric_feet_epsg_only: 'EPSG Métrique / Imperial uniquement',
  min_nc1_days: 'Jours NC1 min',
  min_noise: 'Bruit min',
  min_rms_nc1: 'RMS min NC1',
  minimal_intensity: 'Intensité minimale',
  minimal_noise_sampling_taken_by_the_sensor_prior_to_audio_recording:
    'Échantillonnage de bruit minimal pris par le capteur avant l\’enregistrement audio',
  modem_atenna_signal_strength: 'Force du signal de l\’antenne du modem',
  modem_usage: 'Utilisation du modem',
  more_samples: 'Plus d\’échantillons',
  most_problematic_pipes_based_on_leaks_count:
    'Tuyaux les plus problématiques selon le nombre de fuites',
  most_problematic_pipes_based_on_leaks_per_units:
    'Tuyaux les plus problématiques selon les fuites par {units}',
  multi_diameter: 'Multi-diamètre',
  multi_material: 'Multi-matériau',
  nc3_std_mean_ratio: 'Rapport moyenne Std NC3',
  nc3_proj_mean: 'Moyenne du projet NC3',
  name_: 'Nom : ',
  new_customer: 'Nouveau client',
  new_list_name: 'Nouveau nom de liste',
  new_project: 'Nouveau projet',
  no_leaks: 'Pas de fuites',
  nois_eaudio_duration_ms: 'Durée de l\’audio bruit (ms)',
  noise_alert_const_prob_thr: 'Seuil de probabilité d\’alerte de bruit constant',
  noise_alert_step_prob_thr: 'Seuil de probabilité d\’alerte de bruit par étape',
  noise_audio_fs: 'Fréquence audio de bruit',
  noise_audio_gain: 'Gain audio de bruit',
  noise_intensity_gain: 'Gain d\’intensité de bruit',
  noise_ok_threshold: 'Seuil de bruit acceptable',
  noise_sample: 'Échantillon de bruit',
  noise_samples: 'Échantillons de bruit',
  noise_sync: 'Synchronisation de bruit',
  noise_level_: 'Niveau de bruit',
  noise_sampling_taken_by_the_sensor_during_audio_recording: 'Échantillonnage de bruit pris par le capteur pendant l\’enregistrement audio',
  noise_value_val: 'Valeur du bruit : {val}',
  non_associated: 'Non associé',
  not_active: 'Non actif',
  not_installed: 'Non installé',
  num_coefficients: 'Nombre de coefficients',
  num_frequency_bands: 'Nombre de bandes de fréquence',
  numbadburstsamples_thr: 'Seuil d\’échantillons de rafales défectueuses',
  number_of_samples_: 'Nombre d\’échantillons :',
  number_of_transients: 'Nombre de transitoires',
  open_assessment: 'Ouvrir l\’évaluation',
  open_new_leak_on_0_sample: 'Ouvrir une nouvelle fuite sur 0 échantillon',
  open_projects: 'Ouvrir les projets',
  open_reports: 'Ouvrir les rapports AQS',
  open: 'Ouvrir',
  overground_acc_gps: 'Acc sur sol GPS',
  overground_acc_rf: 'Acc sur sol RF',
  overground_hyd_gps: 'Hyd sur sol GPS',
  overground_hyd_rf: 'Hyd sur sol RF',
  phone: 'Téléphone',
  pipe_accuracy: 'Précision du tuyau',
  pipe_assessment_report: 'Rapport d\’évaluation du tuyau',
  pipe_assessment: 'Évaluation du tuyau',
  pipe_assessments_report_projectname_epsg: 'Rapport des évaluations des tuyaux. {projectName} [EPSG : {epsg}]',
  pipe_assessments: 'Évaluations des tuyaux',
  pipe_condition_analyzer: 'Analyseur d\’état des tuyaux',
  pipe_length_: 'Longueur du tuyau',
  pipe_: 'Tuyau : ',
  pipes_by_assessment: 'Tuyaux par évaluation',
  pipes_by_presure: 'Tuyaux par pression',
  pipes_high_severity_analysis: 'Analyse de gravité élevée des tuyaux :',
  pipes_by_placement_year_: 'Tuyaux par année de pose',
  pipes_by_diameter_: 'Tuyaux par diamètre',
  pipes_by_materials: 'Tuyaux par matériaux',
  pipes_by_presure_: 'Tuyaux par pression',
  pipes_severity_score: 'Score de gravité des tuyaux',
  score: 'Note',
  pipes: 'Tuyaux',
  placement_year: 'Année de pose',
  polyethelen: 'Polyéthylène',
  polygon: 'Polygone',
  poor_condition_pipe: 'Tuyau en mauvais état',
  position_: 'Position : ',
  possible_pumps: 'Pompes possibles',
  pump: 'Pompe',
  prv: 'PRV',
  washout: 'Nettoyage',
  watering: 'Arrosage',
  fountain: 'Fontaine',
  possible_ufr: 'UFR possible',
  pressure_value_coefficient: 'Coefficient de valeur de pression',
  pressure_y_value_coefficient: 'Coefficient de valeur de pression Y',
  print: 'Imprimer',
  prob_irrig: 'Prob Irrig',
  prob4_burst: 'Prob4 Éclatement',
  prob4_consumption: 'Prob4 Consommation',
  processing: 'Traitement',
  profiler: 'Profileur',
  proj_int_factor: 'Facteur d\’intensité du projet',
  proj_int_len_factor: 'Facteur de longueur d\’intensité du projet',
  project_name: 'Nom du projet',
  project_name_: 'Projet : {name}',
  projects_coverage: 'Couverture des projets',
  projects: 'Projets',
  quality_1: 'Qualité 1',
  quality_2: 'Qualité 2',
  quality_: 'Qualité : ',
  quiality_1: 'Qualité 1',
  quiality_2: 'Qualité 2',
  quiality: 'Qualité',

  g5_devices: 'Appareils G5',
  g5_devices_table: 'table des appareils G5',
  g5_logs_table: 'table des journaux G5',
  general: 'Général',
  general_description: 'Description générale',
  general_information: 'Informations générales',
  general_provisions: 'Dispositions générales',
  generate_report: 'Générer un rapport',
  generation: 'Génération',
  get_project_defaults: 'Obtenir les valeurs par défaut du projet',
  grant_of_rights: 'Octroi de droits',
  graph: 'Graphique',
  ground_type: 'Type de sol',
  guidelines: 'Directives :',
  hidden_div_for_generate_plotly_image: 'DIV CACHÉE POUR GÉNÉRER UNE IMAGE PLOTLY',
  historical_information: 'Informations historiques',
  history: 'Historique',
  id: 'ID',
  id_default: 'ID (par défaut)',
  images: 'Images',
  img: 'img',
  in_these_tos: 'dans ces CGU',
  include_map: 'Inclure la carte',
  indemnification: 'Indemnisation',
  indemnity: 'Indemnité',
  index: 'Index',
  install: 'Installer',
  install_device: 'Installer l\’appareil',
  installation_point_id: 'ID du point d\’installation {id}',
  installation_point_id_2: 'ID du point d\’installation',
  installation_points: 'Points d\’installation',
  instance_distances: 'distances des instances',
  instance_dts: 'dTs des instances',
  intellectual_property_rights: 'Droits de propriété intellectuelle',
  intensities: 'Intensités',
  diameter: 'Diamètre',
  intensity_param: 'Intensité : {intensity}',
  add_alert: 'Ajouter une alerte',
  create_alert: 'Créer une alerte',
  audio_alert_3: 'Alerte audio 3',
  audio_base_1: 'Base audio 1',
  audio_base_2: 'Base audio 2',
  audio_sample: 'Échantillon audio',
  audio_sample_clean: 'Échantillon audio-net',
  audio_base_1_clean: 'Base audio 1-net',
  audio_base_2_clean: 'Base audio 2-net',
  audio_alert_3_clean: 'Alerte audio 3-net',
  time_stamp: 'Horodatage',
  alert_3: 'Alerte 3',
  base_1: 'Base 1',
  base_2: 'Base 2',
  event_rms: 'Événement RMS',
  base_mean: 'Moyenne de base',
  base_std: 'Écart type de base',
  samples_mean: 'Moyenne des échantillons',
  samples_std: 'Écart type des échantillons',
  event_gain: 'Gain d\’événement',
  samples_gain: 'Gain des échantillons',
  base_magnitude: 'Magnitude de base',
  samples_magnitude: 'Magnitude des échantillons',
  factor_baseline_rms: 'Facteur RMS',
  alert_samples: 'Échantillons d\’alerte',
  sensor_history: 'Historique du capteur',
  sample_distance: 'Distance de l\’échantillon',
  weighted_distance: 'Distance pondérée',
  peak_0: 'Pic 0 [{ndx}, {maxProb}]',
  peak_1: 'Pic 1 [{ndx}, {maxProb}]',
  peak_2: 'Pic 2 [{ndx}, {maxProb}]',
  send_scheduler_message: 'Envoyer un message du planificateur',
  type_param: 'Type : {type}',
  type: 'Type',
  assessment_param: 'ID de l\évaluation : {id}',
  area_of_interest_id: 'Zone d\’intérêt {id}',
  users_list: 'Liste des utilisateurs',
  select_all_users: 'Sélectionner tous les utilisateurs',
  create_report: 'Créer un rapport',
  save_as_pdf: 'Enregistrer en PDF',
  iq_100b_mobile: 'IQ100B - Mobile',
  device_id_param: 'ID de l\’appareil : {device}',
  detected_date_date: 'Date détectée : {date}',
  pipe_install: 'Installation de tuyau',
  closure_date_date: 'Date de clôture : {date}',
  fixed_date_date: 'Date de réparation : {date}',
  status_state_param: 'Statut : {state}',
  status_param: 'Statut : {status}',
  alert_type_param: 'Type d\’alerte : {alertType}',
  noise_alert_id_noiseId: 'ID d\’alerte de bruit : {noiseId}',
  comment_comment: 'Commentaire : {comment}',
  end_date: 'Date de fin',
  couple_id_param: 'ID du couple : {id}',
  probability_param: 'Probabilité : {probability}',
  leak_boss_param: 'Patron de fuite : {boss}',
  intensity2: 'Intensité :',
  interferences: 'Interférences',
  is_electrolysis: 'Est Électrolyse',
  is_pressure: 'Est Pression',
  is_mini: 'Est G5mini',
  is_previous_break_reported: 'Est rupture précédente signalée',
  is_rocks: 'Est roches',
  is_voids: 'Est vides',
  items: 'Éléments',

  lamppost_number: 'Numéro de lampadaire',
  large_diameter: 'Grand diamètre',
  last_comment: 'Dernier commentaire :',
  last_communication: 'Dernière communication',
  last_event_time: 'Heure du dernier événement',
  last_installation_date: 'Date de dernière installation',
  latitude: 'Latitude',
  launch_street_view_for_selected_item: 'Lancer Street View pour l\’élément sélectionné',
  lead: 'Plomb',
  leak_growth: 'Croissance de la fuite',
  leak_id: 'ID de fuite',
  leak_id2: 'ID de fuite :',
  length: 'Longueur',
  license: 'Licence',
  limitation_of_liability: 'Limitation de responsabilité',
  limitation_of_liability2: 'LIMITATION DE RESPONSABILITÉ',
  limitations: 'Limitations',
  limitations_on_use: 'Limitations d\’utilisation',
  loading: 'Chargement...',
  location_information: 'Informations de localisation',
  logs: 'journaux',
  longitude: 'Longitude',
  longitude_latitude: 'Longitude, Latitude :',
  mail_sms_notification: 'Notification Mail / SMS',
  manage_distribution_lists: 'Gérer les listes de distribution',
  management: 'Gestion',
  map_could_not_be_captured_please_take_a_screenshot: 'La carte n\’a pas pu être capturée. Veuillez prendre une capture d\’écran manuellement.',
  material: 'Matériau',
  material_and_diameter_or_placement_year: 'Matériau & Diamètre / Année de pose',
  material_or_placement_year: 'Matériau / Année de pose',
  materials: 'Matériaux',
  max_probability: 'Probabilité MAX',
  mdpe: 'MDPE',
  mfa_enabled: 'MFA activé',
  metal: 'Métal',
  mfa_code: 'Code MFA :',
  mobile: 'Mobile',
  modification_of_tos: 'Modification des CGU',
  month: 'Mois',
  multifactor_authentication: 'Authentification multi-facteurs',
  n: 'N',
  min_value: 'Valeur min',
  max_value: 'Valeur max',
  median_value: 'Valeur médiane',
  name: 'Nom',
  name_correlation: 'Corrélation {name}',
  nature_of_application_hosting: 'Nature de l\’application &amp; Hébergement',
  nature_of_break_comment: 'Commentaire sur la nature de la rupture',
  network_coverage_gnss_satellites_interruption_of_s: 'Couverture réseau, Satellites GNSS, Interruption de service',
  new_couple: 'Nouveau couple',
  new_list_item: 'Nouvel élément de liste',
  no_data: 'PAS DE DONNÉES',
  no_files_uploaded_yet: 'Aucun fichier téléchargé pour l\’instant...',
  no_filters_applyed: 'Aucun filtre appliqué',
  no_lists: 'Aucune liste',
  noise_NC1_NC2: 'Bruit NC1/NC2',
  noise_alert_options: 'Options d\’alerte de bruit',
  noise_alert_statuses: 'Statuts d\’alerte de bruit',
  noise_interval: 'Intervalle de bruit',
  noise_level: 'Niveau de bruit',
  valve_alert_options: 'Options d\’alerte de vanne',
  control_valve: 'Vanne de contrôle',
  service_valve: 'Vanne de service',
  gate_valve: 'Vanne à guillotine',
  air_valve: 'Vanne d\’air',
  manhole: 'Regard',
  fitting: 'Raccord',
  signals: 'Signaux',
  tensors: 'Tenseurs',
  previous_samples: 'Échantillons précédents',
  fmReceiverSN: 'SN Récepteur FM',
  hardwareRevision: 'Révision du matériel',
  domainName: 'Nom de domaine',
  at_least_length_characters_are_required: 'Au moins {length} caractères sont requis',
  at_least_one_uppercase_letter: 'Exiger des lettres majuscules',
  at_least_one_lowercase_letter: 'Exiger des lettres minuscules',
  at_least_one_number: 'Exiger des chiffres',
  the_passwords_are_not_the_same: 'Les mots de passe ne sont pas identiques',
  invalid_password: 'Mot de passe invalide',
  back_to_signin_page: 'Retour à la page de connexion',
  forgot_your_password: 'Mot de passe oublié ?',
  email_to_reset_password: 'Entrez votre adresse Email ci-dessous et nous vous enverrons un message pour réinitialiser votre mot de passe',
  email_to_reset_password_sent: 'Nous avons envoyé un code de réinitialisation par Email à {mail}. Entrez-le ci-dessous pour réinitialiser votre mot de passe.',
  reset_my_password: 'Réinitialiser mon mot de passe',
  new_password: 'Nouveau mot de passe',
  confirm_new_password: 'Entrez à nouveau le nouveau mot de passe',
  change_password: 'Changer le mot de passe',
  alert_information: 'Informations d\’alerte',
  created_date: 'Date de création',
  raw_intensity: 'Intensité brute',
  filtered_intensity: 'Intensité filtrée',
  consistency: 'Cohérence',
  close_alert: 'Fermer l\’alerte',
  number_of_samples: 'Nombre d\’échantillons',
  load: 'Charger',
  clean_sample: 'Échantillon net',
  sample: 'Échantillon',
  device_type: 'Type d\’appareil : {deviceType}',
  address_param: 'Adresse : {address}',
  reset_password: 'RÉINITIALISER LE MOT DE PASSE',
  rf_sync_problem_: 'Problème de synchronisation RF, ',
  rsrp_dbm: 'RSRP [dBm]',
  radio_: 'Radio ',
  radio_and_audio_window_sec: 'Fenêtre radio et audio (sec)',
  radio_dt_dt_quality_quality: 'Radio [dt : {dt}, qualité : {quality}]',
  ready: 'Prêt',
  record_duration: 'Durée d\’enregistrement',
  recording_duration_sec: 'Durée d\’enregistrement [Sec]',
  recording_durationsec: 'Durée d\’enregistrementSec',
  recordingtime_1: 'Heure d\’enregistrement 1',
  recordingtime_2: 'Heure d\’enregistrement 2',
  recordingtime_3: 'Heure d\’enregistrement 3',
  registered_microsoft_partner: 'Partenaire Microsoft enregistré',
  relative_sensor: 'Capteur relatif',
  reload_data: 'Recharger les données',
  remember_me: 'Se souvenir de moi',
  remove_sensor_sensorid: 'Supprimer le capteur {sensorID}',
  remove: 'Supprimer',
  repair_date_: 'Date de réparation',
  report_enabled: 'Rapport activé',
  reports_settings: 'Paramètres des rapports',
  reports: 'Rapports',
  resets: 'Réinitialisations',
  sms: 'SMS',
  sop_id_: 'ID SOP : ',
  sop_status: 'Statut SOP',
  sop_type: 'Type SOP',
  sops_filters: 'Filtres SOP',
  sample_correlation_contrast: 'Contraste de corrélation d\’échantillon',
  sample_correlation: 'Corrélation d\’échantillon',
  sample_id_: 'ID d\’échantillon : ',
  sample_noise: 'Bruit d\’échantillon',
  sample_size: 'Taille de l\’échantillon',
  sample_spectrogram: 'Spectrogramme d\’échantillon',
  sample_time_: 'Heure de l\’échantillon : ',
  sampled_intensity: 'Intensité échantillonnée',
  samples2: 'Échantillons2',
  sampling_frequency: 'Fréquence d\’échantillonnage',
  save_this_couple_assessment_report: 'Enregistrer ce rapport d\’évaluation de couple',
  save_this_work_order: 'Enregistrer cet ordre de travail',
  second_sensor: 'Deuxième capteur',
  select_projects: 'Sélectionner des projets...',
  select_report: 'Sélectionner un rapport...',
  select_project: 'Sélectionner un projet',
  select_: 'Sélectionner...',
  selected_selectedindex_total_total: 'Sélectionné : {selectedIndex}. Total : {total}',
  send_to_printer: 'Envoyer à l\’imprimante',
  sensitivity_level: 'Niveau de sensibilité',
  sensor_id_: 'ID du capteur : ',
  sensor_id_id: 'ID du capteur : {id}',
  sensor_type: 'Type de capteur',
  sensor_comment_: 'Commentaire du capteur',
  sensor_id_default: 'ID du capteur (par défaut)',
  sensor: 'Capteur',
  sensors_report: 'Rapport des capteurs',
  sensors_filters: 'Filtres des capteurs',
  sensors_options: 'Options des capteurs',
  service_provider_: 'Fournisseur de service',
  settings: 'Paramètres',
  shadow: 'Ombre',
  show_hide: 'Afficher / Masquer',
  show_all: 'Afficher tout',
  show_iquarius_samples: 'Afficher les échantillons iQuarius',
  sim_number: 'Numéro de SIM',
  sopid: 'SopID',
  source_alert: 'Alerte source',
  start_frequency: 'Fréquence de début',
  start_frequency_hz: 'Fréquence de début (Hz)',
  start_time: 'Heure de début',
  state: 'État',
  status_default: 'Statut (par défaut)',
  percent: 'Pourcentage',
  total_count: 'Compte total',
  under_count: 'Compte en dessous',
  over_count: 'Compte au-dessus',
  mixed_over_under_count: 'Compte de type mixte',
  g5_count: 'Compte G5',
  g4_count: 'Compte G4',
  mixed_g_count: 'Compte G mixte',
  project_id: 'ID du projet',
  project_display_name: 'Nom d\’affichage du projet',
  status: 'Statut : ',
  sudden_air_temp_change: 'Changement soudain de température de l\’air',
  task_id: 'ID de tâche',
  time_: 'Heure : ',
  timezone: 'Fuseau horaire',
  total_length_unitslable: 'Longueur totale [{unitsLable}]',
  type_: 'Type : ',
  type_type: 'Type : {type}',
  under: 'Sous',
  underground_acc_gps: 'Acc sous-sol GPS',
  underground_acc_rf: 'Acc sous-sol RF',
  underground_hyd_gps: 'Hyd sous-sol GPS',
  underground_hyd_rf: 'Hyd sous-sol RF',
  uninstall_device_and_remove_sensor: 'Désinstaller l\’appareil et retirer le capteur',
  uninstall_device_and_install_it_on_the_new_sensor: 'Désinstaller l\’appareil et l\’installer sur le nouveau capteur',
  uninstall_comment: 'Commentaire de désinstallation',
  uninstall: 'Désinstaller',
  uninstalled: 'Désinstallé',
  update_customer_details: 'Mettre à jour les détails du client',
  update_pipes: 'Mettre à jour les tuyaux',
  update_project_details: 'Mettre à jour les détails du projet',
  update_user_details: 'Mettre à jour les détails de l\’utilisateur',
  use_feet_in_matlab_graphs: 'Utiliser les pieds dans les graphiques Matlab',
  use_imperial_units_in_graphs: 'Utiliser les unités impériales dans les graphiques',
  user_name_name: 'Utilisateur : {name}',
  users: 'Utilisateurs',
  w: 'W',
  water_loss_unith: 'Perte d\’eau ({unit}/h)',
  weve_sent_a_message_to_email__please_check_your_inbox: 'Nous avons envoyé un message à {email} \n Veuillez vérifier votre boîte de réception.',
  work_area_: 'Zone de travail',
  your_item_located_in_project: 'Votre {item} est situé dans le projet {project}.',
  add_excetional_item: 'Ajouter un élément exceptionnel',
  hz: '[Hz]',
  unitshz: ' [{units}/Hz]',
  balagan_dist_ratio_thr: 'balagan_dist_ratio_thr',
  couple: 'couple',
  false: 'faux',
  first: 'premier',
  ft: 'pi',
  inactive_couple_no_sensors_planning: 'couple inactif - aucun capteur - en planification',
  inactive_couple_no_sensors: 'couple inactif - aucun capteur',
  inactive_couple: 'couple inactif',
  irrig_dist: 'irrig_dist',
  last: 'dernier',
  length_length_units: 'longueur : {length}[{units}]',
  no_correlations_warning: 'pas de corrélations avertissement',
  slope_slope_std_std_r2_r2: 'pente : {slope}, écart type : {std}, r2 : {r2}',
  sum_included_thr: 'somme_incluse_thr',
  update_from_aqs_mobile_details: 'mise à jour depuis AQS Mobile. ({details})',
  name_spectrogram: 'Spectrogramme {name}',
  low_rm_rm: 'faible RM : [{rm}]',
  noise_num_samples: 'Nombre d\’échantillons de bruit',
  noise_tx_time: 'Temps de transmission de bruit',
  average_last: 'Moyenne dernière',
  none: 'Aucun',
  not_found: 'Non trouvé',
  not_include_not_installed_sensors: "* Ne pas inclure les capteurs 'Non installés'",
  notices_and_electronic_communications: 'Avis et communications électroniques',
  official_language: 'Langue officielle',
  op_audio_table: 'table audio {op}',
  or: ' OU “',
  original_audio: 'Audio original',
  other: 'Autre',
  overground: 'Au sol',
  override_length: 'Remplacer la longueur',
  p: 'P',
  payload: 'Charge utile',
  payment: 'Paiement',
  pca: 'PCA',

  sample_images: 'Images d\’échantillon',
  user_sample_classify: 'classer l\’échantillon utilisateur',
  invalid_email_address: 'Adresse e-mail invalide',
  phone_number: 'Numéro de téléphone',
  pictures: 'images',
  pipe: 'Tuyau :',
  pipe_coating: 'Revêtement de tuyau',
  pipe_condition_assessment_details: 'Détails de l\’évaluation de l\’état du tuyau',
  pipe_condition_details: 'Détails de l\’état du tuyau',
  pipe_diameter: 'Diamètre du tuyau',
  pipe_id: 'ID du tuyau',
  pipe_install_year: 'Année d\’installation du tuyau',
  pipe_length: 'Longueur du tuyau',
  pipe_length2: 'Longueur du tuyau :',
  filter_by_customer: 'Filtrer par client',
  check_devices: 'Vérifier les appareils',
  copy_device_id_from_excel: 'Copier l\’ID des appareils depuis Excel',
  count_devices: '{count} appareils',
  selected_devices: '{selected} / {count} appareils',
  pipe_material: 'Matériau du tuyau',
  pipe_section_details: 'Détails de la section du tuyau',
  pipe_thickness: 'Épaisseur du tuyau',
  pipe_thickness_at_break: 'Épaisseur du tuyau (à la rupture)',
  pipe_type: 'Type de tuyau',
  pipes_materials: 'Matériaux des tuyaux :',
  please_uninstall_device: 'Veuillez désinstaller l\’appareil',
  point_1: 'Point 1',
  point_2: 'Point 2',
  poly_ethelen: 'Polyéthylène',
  position: 'Position :',
  preparing_to_download_if_download_does_not_commence_please_allow_popups_for_this_site: 'Préparation du téléchargement. Si le téléchargement ne commence pas, veuillez autoriser les popups pour ce site.',
  previous_break_operating_press: 'Pression de fonctionnement de la rupture précédente',
  prior_2_weeks_weather: 'Météo des 2 semaines précédentes',
  priority: 'Priorité',
  intensity_opr: 'Intensité Opr',
  probability_opr: 'Probabilité Opr',
  work_area: 'Zone de travail',
  repair_code: 'Code de réparation',
  contractor_id: 'ID de l\’entrepreneur',
  detected_at: 'Détecté à',
  priority_default: 'Priorité (par défaut)',
  privacy_statement: 'Déclaration de confidentialité',
  probability: 'Probabilité',
  probability_consumption: 'Probabilité de consommation',
  processing_of_personal_data: 'Traitement des données personnelles',
  products: 'Produits',
  project: 'Projet',
  project2: 'Projet :',
  proximity_to_other_utilities: 'Proximité avec d\’autres services publics',
  prs_calib_multiply: 'PRS Calibration Multiplication',
  prs_calib_shift: 'PRS Calibration Shift',
  pvc: 'PVC',
  quality: 'Qualité :',
  radio: 'Radio',
  radio_frequency: 'Fréquence radio',
  ref_time: 'Heure de référence',
  registration_payment: 'Inscription; Paiement',
  remove_filters_to_view_device: 'Retirer les filtres pour voir l\’appareil',
  remove_filters_to_view_item_location: 'Retirer les filtres pour voir la localisation de {item}',
  remove_sensor: 'Retirer le capteur',
  repair_date: 'Date de réparation',
  repair_details: 'Détails de la réparation',
  add_new_alert: 'Ajouter une nouvelle alerte',
  repair_type: 'Type de réparation',
  detection_date: 'Date de détection',
  report: 'Rapport',
  reported_by: 'Signalé par',
  reset: 'Réinitialiser',
  resistivity: 'Résistivité',
  restrictions_no_misuse_of_services: 'Restrictions - Pas d\’utilisation abusive des services',
  result: 'Résultat',
  s: 'S',
  sample_id: 'ID de l\’échantillon :',
  sample_time: 'Heure de l\’échantillon :',
  sample_time_1: 'Heure de l\’échantillon 1',
  sample_time_2: 'Heure de l\’échantillon 2',
  sample_time_3: 'Heure de l\’échantillon 3',
  samples: 'Échantillons',
  sap_number: 'Numéro SAP :',
  save: 'Enregistrer',
  save_and_apply: 'Enregistrer et appliquer',
  save_as_excel: 'Enregistrer en Excel',
  save_changes: 'Enregistrer les modifications',
  scheduler: 'Planificateur',
  search: 'Rechercher',
  search_box: 'Boîte de recherche',
  sec: 'sec',
  select: 'Sélectionner...',
  select2: 'Sélectionner',
  select_draw_mode_and_draw_area_of_interest: 'Sélectionner le mode de dessin et dessiner la zone d\’intérêt',
  select_sensor: 'Sélectionner le capteur',
  selected: 'Sélectionné : %expr%',
  selected_sensor_id: 'Capteur sélectionné {id}',
  sensor_id_param2: 'Capteur : {id}',
  send: 'Envoyer',
  sensor1_address: 'Adresse Capteur1 :',
  bands: 'Bandes',
  days: 'Jours',
  avg_max_leak_val_ndx: 'Fuite max AVG [ndx : {maxProbIndex}, fuite ndx : {maxProbLeakIndex}, val : {maxProbVal}]',
  sensor2_address: 'Adresse Capteur2 :',
  sensor_activity_threshold: 'Seuil d\’activité du capteur',
  sensor_comment: 'Commentaire du capteur',
  wake_up_before_radio: 'Réveil avant radio',
  random_delay: 'Délai aléatoire',
  sampling_freq: 'Fréquence d\’échantillonnage',
  sensor_id: 'ID du capteur',
  sensor_id2: 'ID du capteur :',
  sensor_id_param: 'ID du capteur : {id}',
  sensor_install: 'Installation du capteur',
  sensor_is_not_found_check_filters_and_try_again: 'Capteur introuvable. Vérifiez les filtres et réessayez',
  sensors: 'Capteurs',
  sensors_nc_table: 'table des capteurs NC',
  service: 'Service',
  service_provider: 'Fournisseur de service',
  service_provider2: 'Fournisseur de service',
  service_subscription: 'Abonnement au service',
  session_max_value: 'Valeur max de la session',
  session_min_value: 'Valeur min de la session',
  session_num_events: 'Nombre d\’événements de la session',
  set_actual_leak_position: 'Définir la position réelle de la fuite',
  set_actual_leak_position_on_map: 'Définir la position réelle de la fuite sur la carte :',
  set_apn: 'Définir l\’APN',
  set_leak_valve_monitor: 'Définir le moniteur de vanne de fuite',
  event_magnitude_constant: 'Magnitude de l\’événement',
  magnitude_threshold: 'Seuil de magnitude',
  leak_monitor_interval_sec: 'Intervalle de surveillance (sec)',
  leak_monitor_alert_suspend_interval_sec: 'Intervalle de suspension de l\’alerte (sec)',
  sample_frequency_hz: 'Fréquence d\’échantillonnage HZ',
  leak_monitor_long_sample_sec: 'Durée de l\’échantillon',
  sample_duration: 'Durée de l\’échantillon',
  notch_filter: 'Filtre en notch',
  compress_enabled: 'Compression activée',
  channel_10k: 'Canal 10k',
  radio_band_mask_1: 'Masque de bande radio 1',
  radio_band_mask_2: 'Masque de bande radio 2',
  radio_band_mask_3: 'Masque de bande radio 3',
  radio_band_mask_4: 'Masque de bande radio 4',
  audio_duration: 'Durée de l\’audio',
  mode: 'Mode',
  cat_m_preferred: 'CAT-M préféré',
  nb_iot_preferred: 'NB-IOT préféré',
  cat_m_only: 'CAT-M seulement',
  zone: 'Zone',
  threshold_3: 'Seuil 3',
  threshold_2: 'Seuil 2',
  threshold_1: 'Seuil 1',
  auto_gain: 'Gain automatique',
  gain_1: 'Gain 1',
  gain_2: 'Gain 2',
  gain_3: 'Gain 3',
  gain_4: 'Gain 4',
  nb_iot_only: 'NB-IOT seulement',
  channel_num: 'Canal {num}',
  radio_duration: 'Durée de la radio',
  audio_fs: 'FS audio',
  sampling_window: 'Fenêtre d\’échantillonnage',
  audio_num_bands: 'Nombre de bandes audio',
  audio_num_coff: 'Nombre de coefficients audio',
  audio_band_mask_1: 'Masque de bande audio 1',
  audio_band_mask_2: 'Masque de bande audio 2',
  audio_band_mask_3: 'Masque de bande audio 3',
  audio_band_mask_4: 'Masque de bande audio 4',
  radio_num_bands: 'Nombre de bandes radio',
  radio_num_coff: 'Nombre de coefficients radio',
  audio_enable: 'Audio activé',
  conf: 'Conf',
  fm2_conf: 'Conf fm2',
  fm1: 'fm1',
  fm2: 'fm2',
  corr_sample_freq: 'Fréquence d\’échantillonnage de corrélation',
  audio_report_enabled: 'Rapport audio activé',
  corr_radio_duration: 'Durée de la radio corrélée',
  corr_audio_duration: 'Durée audio corrélée',
  noise_audio_duration: 'Durée audio du bruit',
  corr_audio_fs: 'FS audio corrélé',
  wakeup_befor: 'Réveil avant',
  random_backoff_connect: 'Repli aléatoire de connexion',
  dfu_fw: 'dfu_fw',
  dfu_type: 'Type dfu',
  dfu_block: 'Bloc dfu',
  modem_fw: 'Modem fw',
  snr1: 'snr1',
  snr2: 'snr2',
  last_updated: 'Dernière mise à jour',
  tlv_server_time: 'Heure du serveur TLV',
  cellular_time: 'Heure cellulaire',
  apn: 'APN',
  domain: 'Domaine',
  modem_version: 'Version du modem',
  search_users: 'Rechercher des utilisateurs',
  search_alert: 'Rechercher une alerte',
  search_sensor: 'Rechercher un capteur',
  model: 'Modèle',
  iemi: 'IMEI',
  iccid: 'ICCID',
  search_installation_point: 'Rechercher un point d\’installation',
  search_interference: 'Rechercher une interférence',
  interference_id: 'ID d\’interférence',
  set_default: 'Définir par défaut',
  prev: 'Précédent',
  next: 'Suivant',
  required: 'Requis',
  search_couple: 'Rechercher un couple',
  continue: 'Continuer',
  you_are_about_to_take_a_significant_action_on_num_devices: 'VOUS ÊTES SUR LE POINT DE REALISER UNE ACTION IMPORTANTE SUR {num} APPAREILS :',
  confirm_updating_devices_apn_to: 'Confirmer la mise à jour des appareils APN vers "{apn}"',
  search_task: 'Rechercher une tâche',
  lte_mode: 'Mode LTE',
  confirm_updating_lte_mode_to: 'Confirmer la mise à jour du mode LTE vers "{mode}"',
  search_user: 'Rechercher un utilisateur',
  search_project: 'Rechercher un projet',
  search_customer: 'Rechercher un client',
  filter_by: 'Filtrer par',
  filter_by_project: 'Filtrer par projet',
  filter_by_customer: 'Filtrer par client',
  set_capability: 'Définir la capacité',
  set_fm_scan: 'Définir le scan FM',
  set_lte_configuration: 'Définir la configuration LTE',
  set_lte_mode: 'Définir le mode LTE',
  set_secondary_server: 'Définir le serveur secondaire',
  low_frequency: 'Basse fréquence',
  high_frequency: 'Haute fréquence',
  medium_frequency: 'Fréquence moyenne',
  secondary: 'secondaire',
  boundaries: 'Limites',
  server: 'serveur',
  set_server: 'Définir le serveur',
  set_time_source: 'Définir la source de temps',
  set_tpcip_config: 'Définir la configuration TPC-IP',
  severity: 'Gravité :',
  show_av: 'Afficher AV',
  site_access_password_security: 'Accès au site &amp; Mot de passe; Sécurité',
  slope_std_r2: 'pente : {slope}, écart type : {std}, r2 : {r2}',
  snow_depth: 'Profondeur de la neige',
  sop_id: 'ID SOP :',
  sops: 'SOPs',
  sort_by: 'Trier par',
  source: 'Source',
  status: 'Statut',
  stolen: 'Volé',
  street_surface: 'Surface de la rue',
  submit: 'Soumettre',
  subscriptions_and_licenses_restrictions: 'Abonnements et licences; Restrictions',
  success: 'Succès',
  sudden_water_temp_change: 'Changement soudain de température de l\’eau',
  supervisor_user: 'Utilisateur superviseur',
  support_services: 'Services de support',
  switch_to_projectName: 'Passer au projet {projectName}',
  switch_to_project: 'Passer au projet {project}',
  switch: 'Basculer',
  sync: 'Synchroniser',
  system: 'Système',
  tabs_container: 'conteneur d\’onglets',
  task_name: 'Nom de la tâche',
  temp_avg: 'Temp Moy',
  temp_fail: 'Échec Temp',
  temp_rise: 'Augmentation Temp',
  term_and_termination: 'Durée et résiliation',
  terms: 'CONDITIONS',
  text_alignment: 'Alignement du texte',
  this_is_a_demo_404_page: 'Ceci est une page de démonstration 404 !',
  this_website_uses_cookies_to_enhance_the_user_expe: 'Ce site utilise des cookies pour améliorer l\’expérience utilisateur.',
  time: 'Temps',
  time_limited_service: '"Service limité dans le temps"',
  time_limited_service_subscription: 'Abonnement au service limité dans le temps',
  time_zone: 'Fuseau horaire',
  to: 'À',
  to2: 'À :',
  to_businesses_and_professionals: ') aux entreprises et professionnels.',
  total: 'Total',
  total2: 'Total :',
  traffic: 'Trafic',
  transient: 'Transitoire',
  trend: 'Tendance',
  type: 'Type',
  u: 'U',
  under_over: 'Sous / Sur',
  underground: 'Sous-sol',
  uninstall_device: 'Désinstaller l\’appareil',
  update: 'Mise à jour',
  update_from_aws_mobile_details: 'Mise à jour depuis AQS Mobile. ({details})',
  update_modem_version: 'Mise à jour de la version du modem',
  update_status_to: 'Statut mis à jour en',
  update_version: 'Mettre à jour la version',
  updated: 'Mis à jour',
  upload_files: 'Télécharger des fichiers',
  upload_screenshot: 'Télécharger une capture d\’écran',
  us_government_restricted_rights: 'Droits restreints du gouvernement américain',
  user: 'Utilisateur',
  delete_user: 'Supprimer l\’utilisateur',
  utc_time: 'Heure UTC',
  v: 'V',
  v_x: 'V : {totalSampledDays}, X : {totalUnSampledDays},',
  v_x2: 'V : {totalSampledDaysWithoutEmpty}, X : {totalUnSampledDaysWithoutEmpty},',
  value: 'Valeur',
  valve: 'Vanne',
  valve_monitoring: 'Surveillance de vanne',
  velocity: 'vitesse',
  version: 'Version',
  wall: 'Mur',
  g3e_fid: 'G3E FID',
  water_loss_unit_h: 'Perte d\’eau ({unit}/h)',
  water_type: 'Type d\’eau',
  pin_location: 'Épingler la localisation',
  weather_conditions: 'Conditions météorologiques',
  week: 'Semaine',
  wood: 'Bois',
  work_area: 'Zone de travail',
  wover: 'wover',
  wt: 'wt',
  x: 'X',
  x_y: 'X , Y :',
  y: 'Y',
  you: 'VOUS',
  ver: 'Ver',
};
