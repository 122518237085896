import React from 'react';
import { translations } from "../../translations/tr";
import I18n from "redux-i18n";
import Loading from "./Loading";
import * as settings from '../../constants/SettingsKeys';


export default class I18nLoading extends React.Component {

    render() {
        const language = localStorage.getItem(settings.LANGUAGE) || 'en';

        return (
            <I18n translations={translations} initialLang={language}>
                <Loading />
            </I18n>
        );
    }

}
